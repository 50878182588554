import React, { useEffect } from "react";
import PageTitle from "../components/common/page_title";
import { useTranslation } from "react-i18next";
import { Button, Input, DatePicker, Row, Col, Form, InputNumber } from "antd";
import LabelValuePair from "../components/common/label_value_pair";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  get_brand_fee,
  get_brand_fee_request,
  update_brand_fee,
  update_brand_fee_clear,
  update_brand_fee_request,
} from "@src/redux";
import { indian_currency_format } from "@src/helpers/constants";
import moment from "moment";
import toast from "react-hot-toast";
import { useCheckPageComponentAccess } from "@src/hooks";

const BrandFee = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { result, error, loading } = useSelector(get_brand_fee);
  const {
    result: update_brand_fee_result,
    error: update_brand_fee_error,
    loading: update_brand_fee_loading,
  } = useSelector(update_brand_fee);

  const handleUpdate = (values) => {
    console.log("Form values:", values);
    dispatch(
      update_brand_fee_request({
        id: result?.id || "",
        data: values,
      })
    );
  };

  useEffect(() => {
    dispatch(get_brand_fee_request({}));
  }, []);

  useEffect(() => {
    if (update_brand_fee_result?.status === "success") {
      toast.success("Brand fee updated successfully");
      dispatch(update_brand_fee_clear());
      form.resetFields();
      dispatch(get_brand_fee_request({}));
    } else if (update_brand_fee_error) {
      toast.success(
        update_brand_fee_error?.message || "Error updating Brad fees"
      );
      dispatch(update_brand_fee_clear());
      form.resetFields();
      dispatch(get_brand_fee_request({}));
    }
  }, [update_brand_fee_result, update_brand_fee_error]);

  return (
    <>
      <div className="product-page-title-container">
        <div className="product-page-title">
          <PageTitle is_color={true} title={t("Brand Fee")} />
        </div>

        <div className="add-drivers-button">
          <Button
            type="primary"
            htmlType="submit"
            form="brandFeeForm"
            loading={update_brand_fee_loading}
          >
            {t("update")}
          </Button>
        </div>
      </div>
      <br />
      <Form
        form={form}
        id="brandFeeForm"
        onFinish={handleUpdate}
        layout="vertical"
        style={{ marginBottom: "20px" }}
      >
        <Row gutter={10}>
          <Col span={10}>
            <LabelValuePair
              label={t("Brand Fees")}
              value={indian_currency_format(result?.price)}
            />
          </Col>
          <Col span={6}>
            <Form.Item
              name="price"
              required
              rules={[
                {
                  required: true,
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(
                        new Error("Please enter brand fees")
                      );
                    } else if (value <= 0) {
                      return Promise.reject(
                        new Error("Negative credits are not allowed")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                type="number"
                placeholder={t("Enter brand fees")}
                className="form-input"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        {useCheckPageComponentAccess("leniency_date") && (
          <Row gutter={10}>
            <Col span={10}>
              <LabelValuePair
                label={t("License Date")}
                value={result?.leniency_date}
              />
            </Col>
            <Col span={6}>
              <Form.Item
                name="leniency_date"
                //   rules={[
                //     {
                //       validator(_, value) {
                //         if (!value) {
                //           return Promise.reject(
                //             new Error("Please enter lenience date")
                //           );
                //         } else if (value <= 0) {
                //           return Promise.reject(
                //             new Error("Negative lenience date not allowed")
                //           );
                //         } else if (value > 20) {
                //           return Promise.reject(
                //             new Error("Maximum lenience date is 20")
                //           );
                //         }
                //         return Promise.resolve();
                //       },
                //     },
                //   ]}
              >
                <InputNumber
                  placeholder={t("Give license date")}
                  className="form-input"
                  style={{ width: "100%" }}
                  min={0}
                  max={25}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
};

export default BrandFee;
