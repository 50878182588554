import React, { useRef, useState } from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  create_customer,
  create_customer_request,
  create_franchise,
  create_franchise_request,
  delete_customer,
  delete_customer_request,
  delete_franchise,
  delete_franchise_request,
  get_customer,
  get_customer_list,
  get_customer_list_request,
  get_customer_request,
  get_district_list,
  get_district_list_request,
  get_franchise,
  get_franchise_list,
  get_franchise_list_request,
  get_franchise_request,
  get_state_list,
  get_state_list_request,
  update_customer,
  update_customer_request,
  update_franchise,
  update_franchise_request,
} from "@src/redux";
import { useNavigate, useParams } from "react-router-dom";
import DynamicTable, {
  DynamicTableColumn,
  DynamicTableHandle,
} from "../general/dynamic_table";
import DynamicForm, {
  DynamicFilterField,
  DynamicFormAction,
  DynamicFormField,
  DynamicFormSource,
} from "../general/dynamic_form";
import { useCheckPageComponentAccess } from "@src/hooks";

type Props = {};

const CustomerOutletsTab = (props: Props) => {
  const navigate = useNavigate();
  const { id: customer_id } = useParams();
  const table_ref = useRef<DynamicTableHandle>(null);
  const [item_id, set_item_id] = useState(null);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [modal_type, set_modal_type] = useState<"add" | "edit">("add");
  console.log(customer_id, "customer_id");

  const { t } = useTranslation();

  const form_fields: DynamicFormField[] = [
    {
      name: "name",
      label: t("table:name"),
      type: "text",
      className: "name-box",
      rules: [{ required: true, message: "Please enter Outlet name" }],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "mobile",
      label: t("table:mobile"),
      type: "mobile",
      maxCount: 10,
      className: "name-box",
      rules: [
        { required: true, message: "Please enter Mobile Number" },
        {
          pattern: /^\d{10}$/,
          message: "Mobile number should be 10 digits",
        },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "email",
      label: t("table:Email"),
      type: "text",
      className: "name-box",
      rules: [
        { required: true, message: "Please enter Email" },
        {
          type: "email",
          message: "Please enter valid Email",
        },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "address",
      label: t("table:Address"),
      type: "textarea",
      className: "name-box",
      rules: [{ required: true, message: "Please enter Outlet address" }],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "district_id",
      label: t("table:District"),
      type: "select",
      className: "name-box",
      source: {
        selector: get_district_list,
        request: get_district_list_request,
      },
      rules: [{ required: true, message: "Please select District" }],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "pincode",
      label: t("table:Pincode"),
      type: "number",
      maxCount: 6,
      className: "name-box",
      rules: [
        { required: true, message: "Please enter Pincode" },
        {
          pattern: /^.{6}$/,
          message: "Please enter valid pincode",
        },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
  ];

  const columns: DynamicTableColumn[] = [
    {
      title: "Name",
      data_index: "name",
      sorter: true,
    },
    {
      title: "Mobile",
      data_index: "mobile",
      sorter: true,
    },
    {
      title: "Email",
      data_index: "email",
      sorter: true,
    },
    {
      title: "Address",
      data_index: "address",
      sorter: true,
    },
    {
      title: "District",
      data_index: "district.name",
    },
    {
      title: "Pincode",
      data_index: "pincode",
      sorter: true,
    },
    {
      title: "Actions",
      fixed: "right",
      action_access_key: "customer_outlet_actions",
      actions: [
        {
          type: "edit",
          access_key: "outlet_edit",
          icon: <EditOutlined />,
        },
        {
          type: "delete",
          access_key: "outlet_delete",
          icon: <DeleteOutlined />,
          title: "Are you sure you want to delete the Outlet {item_name}?",
          title_replace_field: "name",
          content: "Deleting this Outlet cannot be undone.",
          ok_text: "Yes",
          cancel_text: "No",
          source: {
            selector: delete_franchise,
            request: delete_franchise_request,
            filter_key: "id",
            filter_value_field: "id",
            response_path: "result.status",
            response_success_value: "success",
            response_success_message: "Outlet was successfully deleted",
            response_failure_message: "Outlet cannot be deleted",
          },
        },
      ],
    },
  ];

  const form_source: DynamicFormSource = {
    request: get_franchise_request,
    selector: get_franchise,
    params: {
      filter: { id: item_id },
    },
  };

  const form_actions: DynamicFormAction[] = [
    {
      type: "reset",
      label: "Reset",
    },
    {
      type: "cancel",
      label: "Cancel",
    },
    {
      type: "submit",
      label: "Save",
      create_source: {
        request: create_franchise_request,
        selector: create_franchise,
        params: {
          data: {
            customer_id: customer_id,
          },
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Outlet created successfully",
        response_failure_message: "Outlet cannot be created",
      },
      update_source: {
        request: update_franchise_request,
        selector: update_franchise,
        params: {
          id: item_id || "",
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Outlet updated successfully",
        response_failure_message: "Outlet cannot be updated",
      },
    },
  ];

  function on_action(type: any, record?: any) {
    if (type === "edit") {
      set_item_id(record.id);
      set_modal_type(type);
      set_is_modal_open(true);
    } else {
      if (type === "add") {
        set_item_id(null);
        set_modal_type(type);
        set_is_modal_open(true);
      }
    }
  }

  function on_modal_action(type: any, values: any) {
    console.log("action:", type);
  }

  function close_modal(reload: boolean) {
    set_is_modal_open(false);
    set_item_id(null);
    set_modal_type("add");
    if (reload && table_ref.current) {
      table_ref.current.load_data();
    }
  }

  return (
    <>
      <div className="category-page-title-container">
        <div></div>
        <div className="add-drivers-button">
          {useCheckPageComponentAccess("add_outlet_button") && (
            <Button type="primary" onClick={() => on_action("add")}>
              {t("add_Outlet") as any}
            </Button>
          )}
        </div>
      </div>
      <br />
      <DynamicTable
        ref={table_ref}
        columns={columns}
        // filters={filters}
        source={{
          selector: get_franchise_list,
          request: get_franchise_list_request,
          params: {
            filter: {
              customer_id: customer_id,
            },
          },
        }}
        pagination={{
          page_size: 10,
          show_total: "Total {total} Outlets",
        }}
        on_action={on_action}
      />
      <DynamicForm
        view_type="modal"
        type={modal_type}
        modal_title={modal_type === "add" ? t("add_Outlet") : t("edit_Outlet")}
        is_modal_open={is_modal_open}
        close_modal={close_modal}
        source={form_source}
        fields={form_fields}
        actions={form_actions}
        on_action={on_modal_action}
      />
    </>
  );
};

export default CustomerOutletsTab;
