import PageTitle from "../components/common/page_title";
import React, { useRef, useState } from "react";
import DynamicTable, {
  DynamicTableColumn,
  DynamicTableHandle,
} from "../components/general/dynamic_table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DynamicForm, {
  DynamicFilterField,
  DynamicFormAction,
  DynamicFormField,
  DynamicFormSource,
} from "../components/general/dynamic_form";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  create_category,
  create_category_request,
  delete_category,
  delete_category_request,
  get_category_list,
  get_category_list_request,
  get_category,
  get_category_request,
  update_category,
  update_category_request,
} from "@src/redux";
import { useCheckPageComponentAccess } from "@src/hooks";

type Props = {};

const Category = (props: Props) => {
  const table_ref = useRef<DynamicTableHandle>(null);
  const [item_id, set_item_id] = useState(null);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [modal_type, set_modal_type] = useState<"add" | "edit">("add");

  const { t } = useTranslation();

  const form_fields: DynamicFormField[] = [
    {
      name: "name",
      label: t("table:Name"),
      type: "text",
      className: "name-box",
      rules: [
        { required: true, message: "Please enter Category name" },
        {
          pattern: /^[a-zA-Z0-9 ]+$/,
          message: "Only letters and numbers allowed.",
        },
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "image_list",
      label: t("table:image_list"),
      rules: [{ required: true, message: "Please upload image." }],
      type: "image",
      // className: "name-box",
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
      maxCount: 1,
    },
  ];

  const columns: DynamicTableColumn[] = [
    {
      title: "Name",
      sorter: true,
      sorted_by: "name",
      render: (record: any) => (
        <div className="product-container">
          <img
            src={record?.image_list?.[0] || "default-image-url.png"} // Fallback image URL
            className="product-image"
            alt={record?.name || "Product Image"}
          />
          <h5 className="product-name">{`${
            record?.name || "Unnamed Product"
          }`}</h5>
        </div>
      ),
    },
    {
      title: "Actions",
      fixed: "right",
      action_access_key: "category_actions",
      actions: [
        {
          type: "edit",
          access_key: "edit_category",
          icon: <EditOutlined />,
        },
        {
          type: "delete",
          access_key: "delete_category",
          icon: <DeleteOutlined />,
          title: "Are you sure you want to delete the Category {item_name}?",
          title_replace_field: "name",
          content: "Deleting this Category cannot be undone.",
          ok_text: "Yes",
          cancel_text: "No",
          source: {
            selector: delete_category,
            request: delete_category_request,
            filter_key: "id",
            filter_value_field: "id",
            response_path: "result.status",
            response_success_value: "success",
            response_success_message: "Category was successfully deleted",
            response_failure_message: "Category cannot be deleted",
          },
        },
      ],
    },
  ];

  const filters: DynamicFilterField[] = [
    {
      name: "search_string",
      // label: "Search",
      type: "search",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
  ];

  const form_source: DynamicFormSource = {
    request: get_category_request,
    selector: get_category,
    params: {
      filter: { id: item_id },
    },
  };

  const form_actions: DynamicFormAction[] = [
    {
      type: "reset",
      label: "Reset",
    },
    {
      type: "cancel",
      label: "Cancel",
    },
    {
      type: "submit",
      label: "Save",
      create_source: {
        request: create_category_request,
        selector: create_category,
        params: {
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Category created successfully",
        response_failure_message: "Category cannot be created",
      },
      update_source: {
        request: update_category_request,
        selector: update_category,
        params: {
          id: item_id || "",
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Category updated successfully",
        response_failure_message: "Category cannot be updated",
      },
    },
  ];

  function on_action(type: any, record?: any) {
    if (type === "edit") {
      set_item_id(record.id);
      set_modal_type(type);
      set_is_modal_open(true);
    } else {
      if (type === "add") {
        set_item_id(null);
        set_modal_type(type);
        set_is_modal_open(true);
      }
    }
  }

  function on_modal_action(type: any, values: any) {
    console.log("action:", type);
  }

  function close_modal(reload: boolean) {
    set_is_modal_open(false);
    set_item_id(null);
    set_modal_type("add");
    if (reload && table_ref.current) {
      table_ref.current.load_data();
    }
  }

  return (
    <>
      <div className="category-page-title-container">
        <div className="vehicle-page-title">
          <PageTitle is_color={true} title={t("Category") as any} />
        </div>

        <div className="add-drivers-button">
          {useCheckPageComponentAccess("add_category_button") && (
            <Button type="primary" onClick={() => on_action("add")}>
              {t("add_category") as any}
            </Button>
          )}
        </div>
      </div>
      <br />
      <DynamicTable
        ref={table_ref}
        columns={columns}
        filters={filters}
        source={{
          selector: get_category_list,
          request: get_category_list_request,
          params: {
            filter: {},
          },
        }}
        pagination={{
          page_size: 10,
          show_total: "Total {total} Categories",
        }}
        on_action={on_action}
      />
      <DynamicForm
        view_type="modal"
        type={modal_type}
        modal_title={
          modal_type === "add" ? t("add_category") : t("edit_category")
        }
        is_modal_open={is_modal_open}
        close_modal={close_modal}
        source={form_source}
        fields={form_fields}
        actions={form_actions}
        on_action={on_modal_action}
      />
    </>
  );
};

export default Category;
