import {
  get_customer_credit_list_request,
  get_customer_credit_list,
  get_customer_list_request,
  get_customer_list,
  update_customer_credit_request,
  update_customer_credit,
  create_customer_credit_request,
  create_customer_credit,
  delete_customer_credit_request,
  delete_customer_credit,
  get_customer_credit_request,
  get_customer_credit,
  create_gst_request,
  create_gst,
  update_gst,
  update_gst_request,
  get_gst_list_request,
  get_gst_list,
  get_gst_request,
  get_gst,
  get_category_list_request,
  get_category_list,
  delete_gst_request,
  delete_gst,
} from "@src/redux";
import React, { useRef, useState } from "react";
import DynamicTable, {
  DynamicTableColumn,
  DynamicTableHandle,
} from "../components/general/dynamic_table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DynamicForm, {
  DynamicFilterField,
  DynamicFormAction,
  DynamicFormField,
  DynamicFormSource,
} from "../components/general/dynamic_form";
import { Button, Col, Row } from "antd";
import { t } from "i18next";
import PageTitle from "../components/common/page_title";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useCheckPageComponentAccess } from "@src/hooks";

const ProductGST: React.FC = () => {
  const table_ref = useRef<DynamicTableHandle>(null);
  const [item_id, set_item_id] = useState(null);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [modal_type, set_modal_type] = useState<"add" | "edit">("add");

  const { t } = useTranslation();

  const form_fields: DynamicFormField[] = [
    {
      name: "percentage",
      label: t("table:percentage"),
      type: "number",
      maxCount: 3,
      // maxValue: 100,
      // minValue: 0,
      rules: [
        // { required: true, message: "Please Enter Percentage" },
        // {
        //   pattern: /^(?!-)\d+(\.\d+)?$/,
        //   message: "Gst percentage can't be negative.",
        // },
        // {
        //   pattern: /^(100|[1-9]?[0-9])$/,
        //   message: "Please enter a number between 0-100.",
        // },
        () => ({
          validator(_, value) {
            if (!value) {
              return Promise.reject(new Error("Please Enter Percentage"));
            } else if (value <= 0) {
              return Promise.reject(
                new Error("Gst percentage can't be negative.")
              );
            } else if (value > 100) {
              return Promise.reject(
                new Error("Please enter a number between 0-100.")
              );
            }
            return Promise.resolve();
          },
        }),
      ],
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
  ];

  const columns: DynamicTableColumn[] = [
    {
      title: "Percentage",
      data_index: "percentage",
      sorter: true,
    },
    {
      title: "Actions",
      fixed: "right",
      action_access_key: "gst_actions",
      actions: [
        // {
        //   type: "edit",
        //   access_key: "edit_gst",
        //   icon: <EditOutlined />,
        // },
        {
          type: "delete",
          access_key: "delete_gst",
          icon: <DeleteOutlined />,
          title: "Are you sure you want to delete the GST {item_name}?",
          title_replace_field: "percentage",
          content: "Deleting this GST cannot be undone.",
          ok_text: "Yes",
          cancel_text: "No",
          source: {
            request: delete_gst_request,
            selector: delete_gst,
            filter_key: "id",
            filter_value_field: "id",
            response_path: "result.status",
            response_success_value: "success",
            response_success_message: "GST was successfully deleted",
            response_failure_message: "GST cannot be deleted",
          },
        },
      ],
    },
  ];

  const filters: DynamicFilterField[] = [
    {
      name: "category_id",
      label: "Category",
      type: "select",
      source: {
        request: get_category_list_request,
        selector: get_category_list,
      },
      value_field: "category_id",
      class_name: "search-box",
      layout: { span: 4, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 10, sm: 10 },
      wrapper_col: { xs: 14, sm: 14 },
    },
    {
      name: "search_string",
      // label: "Search",
      type: "search",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
  ];

  const form_source: DynamicFormSource = {
    request: get_gst_request,
    selector: get_gst,
    params: {
      filter: { id: item_id },
    },
  };

  const form_actions: DynamicFormAction[] = [
    {
      type: "reset",
      label: "Reset",
    },
    {
      type: "cancel",
      label: "Cancel",
    },
    {
      type: "submit",
      label: "Save",
      create_source: {
        request: create_gst_request,
        selector: create_gst,
        params: {
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "GST created successfully",
        response_failure_message: "GST already available",
      },
      update_source: {
        request: update_gst_request,
        selector: update_gst,
        params: {
          id: item_id || "",
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "GST updated successfully",
        response_failure_message: "GST cannot be updated",
      },
    },
  ];

  function on_action(type: any, record?: any) {
    if (type === "edit") {
      set_item_id(record.id);
      set_modal_type(type);
      set_is_modal_open(true);
    } else {
      if (type === "add") {
        set_item_id(null);
        set_modal_type(type);
        set_is_modal_open(true);
      }
    }
  }

  function on_modal_action(type: any, values: any) {
    console.log("action:", type);
  }

  function close_modal(reload: boolean) {
    set_is_modal_open(false);
    set_item_id(null);
    set_modal_type("add");
    if (reload && table_ref.current) {
      table_ref.current.load_data();
    }
  }

  return (
    <>
      <div className="product-page-title-container">
        <div className="product-page-title">
          <PageTitle is_color={true} title={t("GST")} />
        </div>

        <div className="add-drivers-button">
          {useCheckPageComponentAccess("add_gst") && (
            <Button type="primary" onClick={() => on_action("add")}>
              {t("add_gst")}
            </Button>
          )}
        </div>
      </div>
      <br />
      <DynamicTable
        ref={table_ref}
        columns={columns}
        // filters={filters}
        source={{
          request: get_gst_list_request,
          selector: get_gst_list,
          params: {
            filter: {},
          },
        }}
        pagination={{
          page_size: 10,
          show_total: "Total {total} GST",
        }}
        on_action={on_action}
      />
      <DynamicForm
        view_type="modal"
        type={modal_type}
        modal_title={modal_type === "add" ? t("add_gst") : t("edit_gst")}
        is_modal_open={is_modal_open}
        close_modal={close_modal}
        source={form_source}
        fields={form_fields}
        actions={form_actions}
        on_action={on_modal_action}
      />
    </>
  );
};

export default ProductGST;
