import {
  AppDispatch,
  create_customer_credit_request,
  create_order_line_item,
  create_order_line_item_clear,
  create_order_line_item_request,
  get_dispute,
  get_dispute_request,
  get_order_line_item_list,
  get_order_line_item_list_request,
  get_product_list,
  get_product_list_request,
  Product,
  update_dispute,
  update_dispute_clear,
  update_dispute_request,
  update_order_line_item,
  update_order_line_item_clear,
  update_order_line_item_request,
} from "@src/redux";
import { Button, Col, Form, InputNumber, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

type Props = {
  form: any;
  line_item?: any;
  onClose: Function;
  dispute_status?: string;
};

interface StatusOption {
  label: string;
  value: string;
}

const UpdateReturnModal = (props: Props) => {
  const { form, onClose, line_item, dispute_status } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();

  const { result: get_dispute_details } = useSelector(get_dispute);
  const {
    result: update_dispute_result,
    loading: update_dispute_loading,
    error: update_dispute_error,
  } = useSelector(update_dispute);

  useEffect(() => {
    dispatch(get_product_list_request({}));
  }, [dispatch]);

  useEffect(() => {
    if (update_dispute_result?.status === "success") {
      toast.success("Return order updated successfully");
      dispatch(get_dispute_request({ filter: { id: id } }));
      onClose();
      dispatch(update_dispute_clear());
    } else if (update_dispute_error) {
      toast.error(update_dispute_error.message || "");
      dispatch(update_dispute_clear());
    }
  }, [update_dispute_result, update_dispute_error]);

  const on_finish = (values: any) => {
    if (id && dispute_status) {
      dispatch(
        update_dispute_request({
          id,
          data: {
            status: dispute_status,
            customer_remark: values?.remark,
            total_credits: values?.customer_credits,
          },
        })
      );
    }
    if (values?.customer_credits > 0) {
      dispatch(
        create_customer_credit_request({
          data: {
            customer_id: get_dispute_details?.customer_id,
            amount: values?.customer_credits,
            type: "credit",
          },
        })
      );
    }
  };

  return (
    <div>
      <Form form={form} onFinish={on_finish} layout="vertical">
        {/* <Form.Item
          name={"dispute_status"}
          label={t("status")}
          rules={[{ required: true, message: t("error:status_is_required") }]}
        >
          <Select
            options={Status_options}
            fieldNames={{ label: "label", value: "value" }}
          />
        </Form.Item> */}
        <Form.Item
          name={"remark"}
          label={t("Remark")}
          rules={[
            {
              required: true,
              message: "Please enter reason.",
            },
          ]}
        >
          <TextArea style={{ width: "100%" }} />
        </Form.Item>
        {dispute_status === "accepted" && (
          <Form.Item
            name={"customer_credits"}
            label={t("Credits")}
            rules={[
              {
                required: true,
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(
                      new Error("Please enter credit amount")
                    );
                  }
                  if (value <= 0) {
                    return Promise.reject(
                      new Error("Negative amount cannot be accepted")
                    );
                  }
                  if (
                    get_dispute_details?.total_value &&
                    value > get_dispute_details?.total_value
                  ) {
                    return Promise.reject(
                      new Error(
                        `Maximum credit amount allowed is ₹${get_dispute_details?.total_value}`
                      )
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        )}
        <Row justify={"end"} gutter={10}>
          <Col>
            <Button danger onClick={() => onClose()}>
              {t("close")}
            </Button>
          </Col>
          <Col>
            <Button
              htmlType="submit"
              type="primary"
              loading={update_dispute_loading}
            >
              {t("submit")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UpdateReturnModal;
