import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UiCheckBoxComponent from "../ui_check_box";
import {
  AppDispatch,
  update_system_ui_module_component_clear,
  update_system_ui_module_component_request,
} from "../../../../../redux";
import { update_system_ui_module_component } from "../../../../../redux";

interface Role {
  id?: string;
  name?: string;
}

interface PageComponent {
  id?: string;
  name?: string;
  role_list?: Role[];
}

interface PageUiComponentRoleCheckBoxProps {
  page_component: PageComponent;
  role: Role;
  index?: any;
}

const PageUiComponentRoleCheckBox: React.FC<
  PageUiComponentRoleCheckBoxProps
> = ({ page_component, role, index }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { result } = useSelector(update_system_ui_module_component);

  const [loading_state, set_loading_state] = useState<{
    [key: string]: boolean;
  }>({});
  const [role_id_list, set_role_id_list] = useState<string[]>([]);
  const checkbox_key = `${page_component.id}_${role.id}`;

  useEffect(() => {
    if (page_component) {
      const initial_role_ids =
        page_component.role_list
          ?.map((r) => r.id)
          .filter((id): id is string => id !== undefined) || [];
      set_role_id_list(initial_role_ids);
    }
  }, [page_component]);

  const has_access = role_id_list.includes(role.id || "");

  useEffect(() => {
    if (result?.status === "success") {
      set_loading_state((prevState) => ({
        ...prevState,
        [checkbox_key]: false,
      }));
      dispatch(update_system_ui_module_component_clear());
    }
  }, [result, dispatch, checkbox_key]);

  const on_change_page_access = (value: boolean) => {
    set_loading_state((prevState) => ({
      ...prevState,
      [checkbox_key]: true,
    }));

    set_role_id_list((prev) => {
      if (role?.id) {
        return value ? [...prev, role.id] : prev.filter((id) => id !== role.id);
      }
      return prev;
    });
    console.log(role.id, value);
    if (value) {
      dispatch(
        update_system_ui_module_component_request({
          id: page_component.id || "",
          data: {
            role_id_list: [role.id || ""],
          },
        })
      );
    } else {
      dispatch(
        update_system_ui_module_component_request({
          id: page_component.id || "",
          data: {
            removable_role_id_list: [role.id || ""],
          },
        })
      );
    }
  };

  return (
    <UiCheckBoxComponent
      key={checkbox_key}
      is_checked={has_access}
      loading={loading_state[checkbox_key] || false}
      onChange={on_change_page_access}
      index={index}
    />
  );
};

export default PageUiComponentRoleCheckBox;
