import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_dispute($filter: dispute_filter_input) {
    get_dispute(filter: $filter) {
      id
      customer_id
      franchise_id
      reference
      total_value
      remark
      customer_remark
      order_id
      total_credits
      status
      image_list
      customer {
        email
        mobile
        name
        id
      }
      order {
        id
        reference
      }
      franchise {
        email
        mobile
        district_id
        pincode
        address
        customer_id
        name
        id
      }
      line_item_list {
        order_line_item {
          price
          gst
        }
        credits
        quantity
        status
        order_line_item_id
        dispute_id
        id
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.DisputeState = {
  result: null,
  error: null,
  loading: false,
};

export const get_dispute_request = createAsyncThunk<
  TYPES.Dispute,
  { filter?: TYPES.DisputeFilterInput },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "dispute/get_dispute",
  async (params: { filter?: TYPES.DisputeFilterInput }, { extra: client }) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_dispute;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_dispute_slice = createSlice({
  name: "get_dispute",
  initialState: initial_state,
  reducers: {
    get_dispute_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_dispute_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_dispute_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_dispute_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_dispute_clear } = get_dispute_slice.actions;

export const get_dispute = createSelector(
  (state: RootState) => state.dispute.get_dispute.result,
  (state: RootState) => state.dispute.get_dispute.error,
  (state: RootState) => state.dispute.get_dispute.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_dispute_slice.reducer;
