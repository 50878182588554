import React, { useEffect } from "react";
import { Button, Col } from "antd";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
// import {
//   introspection_query,
//   sync_system_api_modules_mutation,
// } from "@services/graphql/access_rights";
import {
  AppDispatch,
  sync_system_api_module_list,
  sync_system_api_module_list_clear,
  sync_system_api_module_list_request,
} from "../../../../redux/index";
import {
  schema,
  schema_clear,
  schema_request,
} from "@src/redux/slices/system_user/schema";

const SyncEndPoints: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, result: types } = useSelector(schema);
  const { loading: sync_loading, result: sync_system_api_module } = useSelector(
    sync_system_api_module_list
  );
  // console.log("types", types);

  useEffect(() => {
    if (types && types?.types?.length > 0) {
      const queries =
        types?.types
          ?.find((x: any) => x.name === "Query")
          ?.fields.map((x: any) => x.name) || [];
      const mutations =
        types?.types
          .find((x: any) => x.name === "Mutation")
          ?.fields.map((x: any) => x.name) || [];
      const api_name_list: any[] = [...queries, ...mutations];
      dispatch(schema_clear());
      dispatch(
        sync_system_api_module_list_request({
          data: { api_name_list: api_name_list },
        })
      );
    }
  }, [types, dispatch]);

  useEffect(() => {
    if (sync_system_api_module?.status === "success") {
      toast.success(`Endpoints synced successfully`);
      dispatch(sync_system_api_module_list_clear());
    }
  }, [sync_system_api_module]);

  const handleSyncEndpoints = () => {
    dispatch(schema_request({}));
  };

  return (
    <div>
      <Col span={10}>
        <Button
          onClick={handleSyncEndpoints}
          loading={sync_loading}
          className="add-button"
        >
          Sync EndPoints
        </Button>
      </Col>
    </div>
  );
};

export default SyncEndPoints;
