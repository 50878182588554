import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EndPoints from "./end_points";
import toast from "react-hot-toast";
import {
  AppDispatch,
  get_system_ui_module_list_request,
  update_system_ui_module_component,
  update_system_ui_module_component_clear,
  update_system_ui_module_component_request,
} from "../../../../redux";
import {
  get_system_api_module_list,
  get_system_api_module_list_request,
} from "@src/redux/slices/system_api_module/get_system_api_module_list";

// Define the types for props
interface EndPointFormProps {
  get_id: {
    id: string;
    name: string;
  };
  link_type: string;
  set_loading: (loading: boolean) => void;
  action_item: any;
  close: () => void;
  action_type: string;
}

const EndPointForm: React.FC<EndPointFormProps> = ({
  get_id,
  link_type,
  set_loading,
  action_item,
  close,
  action_type,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();

  const { result: api_list } = useSelector(get_system_api_module_list);

  const {
    result: update_status,
    loading: update_loading,
    error: update_error,
  } = useSelector(update_system_ui_module_component);

  useEffect(() => {
    if (link_type === "link_component" && update_status?.status === "success") {
      toast.success("Link to page component updated successfully");
      // system_api_module_list();
      dispatch(get_system_ui_module_list_request({}));

      close();
      dispatch(update_system_ui_module_component_clear());
    } else if (update_error?.message) {
      toast.error(update_error.message);
      dispatch(update_system_ui_module_component_clear());
    }
  }, [update_status, update_error, link_type, close, dispatch]);

  useEffect(() => {
    set_loading(update_loading);
  }, [update_loading, set_loading]);

  const system_api_module_list = () => {
    dispatch(get_system_api_module_list_request({}));
  };

  useEffect(() => {
    system_api_module_list();
  }, [dispatch]);

  const update_page_component = (data: { [key: string]: any }) => {
    let variables = { data, id: get_id?.id || "" };
    dispatch(update_system_ui_module_component_request(variables));
  };

  const on_finish = (values: any) => {
    update_page_component({
      ...values,
      id: get_id?.id,
      name: get_id?.name,
    });
  };

  useEffect(() => {
    if (action_item?.api_module_list?.length > 0) {
      form.setFieldsValue({
        api_module_id_list: action_item?.api_module_list?.map(
          (item: any) => item?.id
        ),
      });
    } else {
      form.resetFields();
    }
  }, [action_item, form]);

  return (
    <div>
      <Form
        name="create page link"
        onFinish={on_finish}
        id="link_form"
        form={form}
      >
        <EndPoints api_list={api_list?.items || []} />
      </Form>
    </div>
  );
};

export default EndPointForm;
