import {
  AppDispatch,
  create_order,
  create_order_request,
  get_customer_list,
  get_customer_list_request,
  get_franchise_list,
  get_franchise_list_request,
  get_product_list,
  get_product_list_request,
  Product,
} from "@src/redux";
import { Button, Col, Form, InputNumber, Row, Select } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

type Props = {
  onCancel: Function;
};

const CustomerOrderForm = (props: Props) => {
  const { onCancel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { result: customer_list } = useSelector(get_customer_list);
  const { result: customer_outlet_list } = useSelector(get_franchise_list);
  const { result: product_list } = useSelector(get_product_list);
  const [form] = Form.useForm();
  const {
    result: create_order_request_result,
    loading: create_order_request_loading,
  } = useSelector(create_order);

  const handle_change = (value: any, values: any) => {
    if (values?.customer_id) {
      dispatch(
        get_franchise_list_request({
          filter: { customer_id: values?.customer_id },
        })
      );
    } else {
      form.setFieldValue("franchise_id", null);
    }
  };

  useEffect(() => {
    dispatch(get_customer_list_request({}));
    dispatch(get_product_list_request({}));
  }, [dispatch]);

  // Helper function to filter product list
  const getFilteredProductOptions = (currentIndex: number) => {
    const selectedProducts = form.getFieldValue("line_item_list");

    // Get the selected product_ids from the list except for the current index
    const selectedProductIds = selectedProducts
      ?.filter((_: any, idx: number) => idx !== currentIndex)
      ?.map((item: any) => item?.product_id);

    // Filter the product list to remove already selected products
    return (
      product_list?.items &&
      product_list?.items.filter(
        (product: any) => !selectedProductIds?.includes(product?.id)
      )
    );
  };
  const handle_finish = (values: any) => {
    values.line_item_list = values.line_item_list.map((list: any) => {
      let get_one_product = product_list?.items?.find((product: Product) =>
        product.id?.includes(list?.product_id)
      );
      return {
        product_id: get_one_product?.id,
        price: get_one_product?.price,
        quantity: list?.quantity,
        gst: get_one_product?.gst?.percentage,
      };
    });

    console.log(values, "valuesvalues");

    dispatch(create_order_request({ data: values }));
  };
  const handle_close = () => {
    onCancel();
  };

  return (
    <div>
      <Form
        layout="vertical"
        onValuesChange={handle_change}
        form={form}
        onFinish={handle_finish}
      >
        <Form.Item
          name={"customer_id"}
          label={t("customer_name")}
          rules={[{ required: true, message: t("error:name_is_required") }]}
        >
          <Select
            allowClear={true}
            showSearch={true}
            options={[...(customer_list?.items || [])].sort((a: any, b: any) =>
              a.name.localeCompare(b.name)
            )}
            fieldNames={{ label: "name", value: "id" }}
            filterOption={(input: any, option: any) => {
              return (option?.name).toLowerCase().includes(input.toLowerCase());
            }}
          />
        </Form.Item>
        <Form.Item
          name={"franchise_id"}
          label={t("outlet")}
          rules={[{ required: true, message: t("error:outlet_is_required") }]}
        >
          <Select
            options={customer_outlet_list?.items || []}
            fieldNames={{ label: "name", value: "id" }}
          />
        </Form.Item>
        <Form.List name="line_item_list">
          {(fields, { add, remove }) => (
            <>
              <Row justify={"space-between"} align={"middle"}>
                <Col>
                  <h3>{t("products")}</h3>
                </Col>
                <Col>
                  <Button type="primary" onClick={() => add()} block>
                    Add
                  </Button>
                </Col>
              </Row>
              {fields.map(({ key, name, index }: any) => (
                <Row key={key} gutter={16}>
                  <Col span={10}>
                    <Form.Item
                      label="Product"
                      name={[name, "product_id"]}
                      rules={[
                        {
                          required: true,
                          message: t("error:product_is_required"),
                        },
                      ]}
                    >
                      <Select
                        // options={getFilteredProductOptions(index)}
                        options={product_list?.items}
                        fieldNames={{ label: "name", value: "id" }}
                        onChange={() => {
                          form.setFieldsValue({
                            line_item_list:
                              form.getFieldValue("line_item_list"),
                          });
                        }}
                        allowClear={true}
                        showSearch={true}
                        filterOption={(input: any, option: any) => {
                          return (option?.name)
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={10}>
                    <Form.Item
                      label="Quantity"
                      name={[name, "quantity"]}
                      rules={[
                        {
                          required: true,
                          message: t("error:quantity_is_required"),
                        },
                      ]}
                    >
                      <InputNumber changeOnWheel={false} controls={false} />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Button
                      onClick={() => remove(name)}
                      style={{ marginTop: "30px" }}
                      danger
                    >
                      <MdDelete />
                    </Button>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
        <Row justify={"end"} gutter={10} style={{ marginTop: "10px" }}>
          <Col>
            <Button onClick={handle_close} danger>
              {t("close")}
            </Button>
          </Col>
          <Col>
            <Button
              htmlType="submit"
              type="primary"
              loading={create_order_request_loading}
            >
              {t("submit")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CustomerOrderForm;
