import {
  AppDispatch,
  create_customer_credit_request,
  create_order_line_item,
  create_order_line_item_clear,
  create_order_line_item_request,
  get_dispute,
  get_dispute_request,
  get_order,
  get_order_line_item_list,
  get_order_line_item_list_request,
  get_order_request,
  get_product_list,
  get_product_list_request,
  Product,
  update_dispute,
  update_dispute_clear,
  update_dispute_request,
  update_order,
  update_order_clear,
  update_order_line_item,
  update_order_line_item_clear,
  update_order_line_item_request,
  update_order_request,
} from "@src/redux";
import { Button, Col, Form, InputNumber, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

type Props = {
  form: any;
  line_item?: any;
  onClose: Function;
  dispute_status?: string;
};

interface StatusOption {
  label: string;
  value: string;
}

const UpdateOrderModal = (props: Props) => {
  const { form, onClose, line_item } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();

  const {
    result: update_order_result,
    loading: update_order_loading,
    error: update_order_error,
  } = useSelector(update_order);
  console.log(update_order_loading, update_order_result, "update_order_result");

  useEffect(() => {
    if (update_order_result?.status === "success") {
      dispatch(get_order_request({ filter: { id: id } }));
      onClose();
      dispatch(update_order_clear());
    } else if (update_order_error) {
      toast.error(update_order_error.message || "");
      dispatch(update_order_clear());
    }
  }, [update_order_result, update_order_error]);

  const on_finish = (values: any) => {
    if (id) {
      dispatch(
        update_order_request({
          id,
          data: {
            tracking_no: values?.tracking_no,
            status: "delivered",
          },
        })
      );
    }
  };

  return (
    <div>
      <Form form={form} onFinish={on_finish} layout="vertical">
        <Form.Item
          name={"tracking_no"}
          label={t("Tracking Id")}
          rules={[
            {
              required: true,
              message: "Please enter order tracking id.",
            },
          ]}
        >
          <TextArea style={{ width: "100%" }} />
        </Form.Item>

        <Row justify={"end"} gutter={10}>
          <Col>
            <Button danger onClick={() => onClose()}>
              {t("close")}
            </Button>
          </Col>
          <Col>
            <Button
              htmlType="submit"
              type="primary"
              loading={update_order_loading}
            >
              {t("submit")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UpdateOrderModal;
