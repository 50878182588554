import { useCheckPageAccess } from "@src/hooks";
import { useTranslation } from "react-i18next";
import { BiSolidCategory } from "react-icons/bi";
import { FaBitcoin, FaBoxes, FaBoxOpen, FaShoppingCart } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { MdAssignmentReturn } from "react-icons/md";
import { PiSealPercentFill } from "react-icons/pi";
import { TbTransactionRupee } from "react-icons/tb";
import { AiFillDollarCircle } from "react-icons/ai";

interface MenuItem {
  name: string;
  pathname?: string;
  route?: string;
  Icon: any;
  key?: string;
  keys?: string[];
  children?: MenuItem[]; // Add this for nested items
}
export const menuItems: MenuItem[] = [
  {
    name: "customer_orders",
    pathname: "/admin/customer-orders",
    route: "customer-orders",
    Icon: FaShoppingCart,
    key: "Customer Orders",
  },
  {
    name: "stock_management",
    pathname: "/admin/stock-management",
    route: "stock-management",
    Icon: FaBoxes,
    key: "Stock Management",
  },
  {
    name: "return_orders",
    pathname: "/admin/return-orders",
    route: "return-orders",
    Icon: MdAssignmentReturn,
    key: "Return Order",
  },
  {
    name: "customer_credits",
    pathname: "/admin/customer-credits",
    route: "customer-credits",
    Icon: FaBitcoin,
    key: "Customer Credits",
  },
  {
    name: "Customers",
    pathname: "/admin/customers",
    route: "customers",
    Icon: IoIosPeople,
    key: "Customers",
  },

  {
    name: "payment_transaction",
    pathname: "/admin/payment-transaction",
    route: "payment-transaction",
    Icon: TbTransactionRupee,
    key: "Payment Transaction",
  },

  {
    name: "products",
    pathname: "/admin/products",
    route: "products",
    Icon: FaBoxOpen,
    key: "Products",
  },
  {
    name: "category",
    pathname: "/admin/categories",
    route: "categories",
    Icon: BiSolidCategory,
    key: "Categories",
  },

  {
    name: "product_gst",
    pathname: "/admin/product-gst",
    route: "product-gst",
    Icon: PiSealPercentFill,
    key: "Product GST",
  },
  {
    name: "brand_fee",
    pathname: "/admin/brand-fee",
    route: "brand-fee",
    Icon: AiFillDollarCircle,
    key: "Brand Fee",
  },
];

export const filterMenu = (menu: MenuItem[]) => {
  return menu.filter((item: MenuItem) => {
    if (item?.children) {
      item.children = filterMenu(item?.children);
    }
    if (item?.key) {
      return useCheckPageAccess(item?.key);
    } else if (item?.keys) {
      return item.keys.some((key) => useCheckPageAccess(key));
    }
    return false;
  });
};
