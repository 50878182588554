import { combineReducers } from "@reduxjs/toolkit";
import login_reducer from "./login";
import schema_reducer from "./schema";

const system_user_reducer = combineReducers({
  login: login_reducer,
  schema: schema_reducer,
});

export default system_user_reducer;
