import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clear_local_storage,
  retrieve_item,
  store_item,
} from "@src/helpers/functions";
import toast from "react-hot-toast";
import {
  AppDispatch,
  login,
  login_clear,
  login_request,
  useDynamicSelector,
} from "@src/redux";
import {
  BLACK_PEKOE,
  HEADER_LOGO,
  HEADER_LOGO_BP,
  VERTACE_LOGO,
} from "@src/helpers/images_constants";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaHome } from "react-icons/fa";
// import { LOGO_WIDTH_TITLE, VERTACE_LOGO } from "@src/helpers/images_constants";

interface LoginData {
  username: string;
  password: string;
}

interface LoginResults {
  status?: string;
  session_id?: string;
  user_id?: string;
  page_accesses?: string[];
  page_component_accesses?: string[];
  system_user?: {
    role_list?: { name: string }[];
  };
  error?: {
    message: string;
  };
  loading?: boolean;
}

const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { brand } = useDynamicSelector("brand");
  const get_brand_type = retrieve_item("brand");
  console.log("get_brand_type", get_brand_type);

  // const handle_login = useAPIRequest("login", query_login);
  const {
    result: login_results,
    loading: login_loading,
    error: login_error,
  } = useSelector(login);
  console.log(login_results, "login_results");

  const onFinish = (data: LoginData) => {
    dispatch(login_request({ data }));
  };

  const onFinishFailed = (errorInfo: any) => {
    // You can handle the error here if needed
  };

  const handle_redirect = () => {
    window.open("https://vertace.com/");
  };

  useEffect(() => {
    if (login_results?.status === "success") {
      store_item("session_id", login_results?.session_id);
      store_item("admin_user_id", login_results?.user_id);
      store_item("page_accesses", login_results?.page_accesses);
      store_item("user_name", login_results?.system_user?.name);
      store_item(
        "page_component_accesses",
        login_results?.page_component_accesses
      );
      store_item("admin_user_details", login_results?.system_user);
      if (login_results?.system_user?.role_list?.[0]?.name === "warehouse") {
        navigate("/admin/customer-orders");
      } else if (login_results?.system_user?.role_list?.[0]?.name === "admin") {
        navigate("/admin/customer-orders");
      } else if (
        login_results?.system_user?.role_list?.[0]?.name === "superadmin"
      ) {
        navigate("/admin/access-rights");
      } else {
        toast.error("Invalid User...");
        dispatch(login_clear());
        clear_local_storage();
      }
      toast.success("Logged in successfully..");
      dispatch(login_clear());
    } else if (login_error?.message) {
      toast.error(
        login_error?.message?.includes("prisma") ? "" : login_error?.message
      );
      dispatch(login_clear());
    } else if (login_results?.status === "failure") {
      dispatch(login_clear());
      toast.error("Invalid username or password");
    }
  }, [login_results, navigate, dispatch]);

  const handle_forget_password = () => {
    navigate("/admin/forget-password");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <div className="login-logo">
          <img
            src={get_brand_type === "teaboy" ? HEADER_LOGO : BLACK_PEKOE}
            alt="Teaboy"
          />
        </div>
      </div>
      <div className="login-right">
        {/* <div className="back-button">
          <Button type="text" onClick={handleBack}>
            <IoMdArrowRoundBack size={23} />
          </Button>
        </div> */}

        <h2>{t("login")}</h2>
        <Form
          name="login"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="login-form"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="username"
            rules={[
              { required: true, message: t("error:email_is_required") },
              {
                type: "email",
                message: "Please enter valid Email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: t("error:password_is_required") },
              // {
              //   pattern: /^.{6,12}$/,
              //   message: "Password must be between 6 and 12 characters.",
              // },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Row align="stretch" justify="center">
            <Col span={24} className="login-buttons">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="admin-login-button"
                  loading={login_loading || false}
                >
                  Login
                </Button>
              </Form.Item>
            </Col>
            <Col span={24} className="login-buttons">
              <Form.Item>
                <Button type="link" onClick={handle_forget_password}>
                  Forgot password?
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="home-button">
          <Button type="text" onClick={handleBack}>
            <FaHome size={23} />
            Home
          </Button>
        </div>
        <div className="login-footer-container">
          <span>{t("powered_by")}</span>
          <img
            src={VERTACE_LOGO}
            className="vertace-logo"
            onClick={handle_redirect}
            alt="Vertace"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
