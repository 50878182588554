import {
  get_customer_credit_list_request,
  get_customer_credit_list,
  get_customer_list_request,
  get_customer_list,
  update_customer_credit_request,
  update_customer_credit,
  create_customer_credit_request,
  create_customer_credit,
  delete_customer_credit_request,
  delete_customer_credit,
  get_customer_credit_request,
  get_customer_credit,
  AppDispatch,
} from "@src/redux";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable, {
  DynamicTableColumn,
  DynamicTableHandle,
} from "../components/general/dynamic_table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DynamicForm, {
  DynamicFilterField,
  DynamicFormAction,
  DynamicFormField,
  DynamicFormSource,
} from "../components/general/dynamic_form";
import { Button, Col, Row, Typography } from "antd";
import { t } from "i18next";
import PageTitle from "../components/common/page_title";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useCheckPageComponentAccess } from "@src/hooks";
import { formatStatus } from "@src/helpers/status_constants";
import { useDispatch, useSelector } from "react-redux";
import { indian_currency_format } from "@src/helpers/constants";
const { Text } = Typography;

const CustomerCredits: React.FC = () => {
  const table_ref = useRef<DynamicTableHandle>(null);
  const [item_id, set_item_id] = useState(null);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [modal_type, set_modal_type] = useState<"add" | "edit">("add");
  const dispatch = useDispatch<AppDispatch>();
  const { result: customer_list } = useSelector(get_customer_list);
  useEffect(() => {
    dispatch(get_customer_list_request({}));
  }, []);

  const { t } = useTranslation();

  const form_fields: DynamicFormField[] = [
    {
      name: "customer_id",
      label: t("table:customer"),
      type: "select",
      rules: [{ required: true, message: "Please select Customer" }],
      source: {
        request: get_customer_list_request,
        selector: get_customer_list,
      },
      labelField: "name",
      valueField: "id",
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "amount",
      label: t("table:credit_points"),
      type: "number",
      rules: [
        {
          required: true,
          validator(_, value) {
            if (!value) {
              return Promise.reject(new Error("Please enter Credit Points"));
            } else if (value <= 0) {
              return Promise.reject(
                new Error("Negative credits are not allowed")
              );
            }
            return Promise.resolve();
          },
        },
      ],
      className: "name-box",
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
  ];

  const columns: DynamicTableColumn[] = [
    {
      title: "Customer Name",
      data_index: "customer.name",
      // render: (record: any) => (
      //   <p style={{ color: "#19B1FF", cursor: "pointer" }}>{record}</p>
      // ),
    },
    {
      title: "Credit On",
      data_index: "created_datetime",
      sorter: true,
      render: (record: Date) => moment(record).format("DD/MM/YYYY"),
    },
    {
      title: "Type",
      data_index: "type",
      sorter: true,
      render: formatStatus,
    },
    {
      title: "Credit/Debit by",
      data_index: "credited_by_user_id",
      render: (record: string) =>
        customer_list?.items?.find((customer: any) => customer?.id === record)
          ?.name || "Admin",
    },
    {
      title: "Credit Points",
      data_index: "amount",
      sorter: true,
      render: (record: number) => indian_currency_format(record),
    },

    // {
    //   title: "Actions",
    //   fixed: "right",
    //   actions: [
    //     {
    //       type: "edit",
    //       icon: <EditOutlined />,
    //     },
    //     {
    //       type: "delete",
    //       icon: <DeleteOutlined />,
    //       title:
    //         "Are you sure you want to delete the Credit Point {item_name}?",
    //       title_replace_field: "name",
    //       content: "Deleting this Products cannot be undone.",
    //       ok_text: "Yes",
    //       cancel_text: "No",
    //       source: {
    //         request: delete_customer_credit_request,
    //         selector: delete_customer_credit,
    //         filter_key: "id",
    //         filter_value_field: "id",
    //         response_path: "result.status",
    //         response_success_value: "success",
    //         response_success_message: "Products was successfully deleted",
    //         response_failure_message: "Products cannot be deleted",
    //       },
    //     },
    //   ],
    // },
  ];

  const filters: DynamicFilterField[] = [
    {
      name: "created_datetime",
      label: "Credited date",
      type: "date",
      value_field: "created_datetime",
      class_name: "search-box",
      layout: { span: 6, xs: 6, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
    {
      name: "customer_id",
      label: "Customer",
      type: "select",
      source: {
        request: get_customer_list_request,
        selector: get_customer_list,
      },
      value_field: "customer_id",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
    {
      name: "search_string",
      label: " ",
      type: "search",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
  ];

  const form_source: DynamicFormSource = {
    request: get_customer_credit_request,
    selector: get_customer_credit,
    params: {
      filter: { id: item_id },
    },
  };

  const form_actions: DynamicFormAction[] = [
    {
      type: "reset",
      label: "Reset",
    },
    {
      type: "cancel",
      label: "Cancel",
    },
    {
      type: "submit",
      label: "Save",
      create_source: {
        request: create_customer_credit_request,
        selector: create_customer_credit,
        params: {
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Credit added successfully",
        response_failure_message: "Failed to add created",
      },
      update_source: {
        request: update_customer_credit_request,
        selector: update_customer_credit,
        params: {
          id: item_id || "",
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Credit updated successfully",
        response_failure_message: "Failed to update created",
      },
    },
  ];

  function on_action(type: any, record?: any) {
    if (type === "edit") {
      set_item_id(record.id);
      set_modal_type(type);
      set_is_modal_open(true);
    } else {
      if (type === "add") {
        set_item_id(null);
        set_modal_type(type);
        set_is_modal_open(true);
      }
    }
  }

  function on_modal_action(type: any, values: any) {
    console.log("action:", type);
  }

  function close_modal(reload: boolean) {
    set_is_modal_open(false);
    set_item_id(null);
    set_modal_type("add");
    if (reload && table_ref.current) {
      table_ref.current.load_data();
    }
  }

  return (
    <>
      <div className="product-page-title-container">
        <div className="product-page-title">
          <PageTitle is_color={true} title={t("customer_credits")} />
        </div>

        <div className="add-drivers-button">
          {useCheckPageComponentAccess("add_credit_button") && (
            <Button type="primary" onClick={() => on_action("add")}>
              {t("add_credit")}
            </Button>
          )}
        </div>
      </div>
      <br />
      <DynamicTable
        ref={table_ref}
        columns={columns}
        filters={filters}
        source={{
          request: get_customer_credit_list_request,
          selector: get_customer_credit_list,
          params: {
            filter: {},
            sort: [{ field: "created_datetime", order: "desc" }],
          },
        }}
        pagination={{
          page_size: 10,
          show_total: "Total {total} Customer Credits",
        }}
        on_action={on_action}
      />
      <DynamicForm
        view_type="modal"
        type={modal_type}
        modal_title={modal_type === "add" ? t("add_credit") : t("edit_product")}
        is_modal_open={is_modal_open}
        close_modal={close_modal}
        source={form_source}
        fields={form_fields}
        actions={form_actions}
        on_action={on_modal_action}
      />
    </>
  );
};

export default CustomerCredits;
