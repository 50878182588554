import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Switch,
} from "antd";
import { useDispatch } from "react-redux";
import lodash from "lodash";
import store from "@src/redux/store";
import { DynamicFilterField } from "../dynamic_form";
import SearchBox from "../../common/search_box";
import dayjs from "dayjs";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

const FilterBox: React.FC<any> = (props) => {
  const { filters, params, set_params } = props;
  console.log("paramsparamsparams", params, filters);

  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const [table_filters, set_table_filters] = useState<Record<string, any>>({});
  const [select_options, set_select_options] = useState<Record<string, any>>(
    {}
  );
  const [form] = Form.useForm();

  const load_filter_options = async () => {
    const promises = filters
      .filter(
        (field: any) =>
          (field.type === "select" ||
            field.type === "checkbox" ||
            field.type === "radio") &&
          field.source
      )
      .map(async (field: any) => {
        const { request, selector } = field.source!;
        try {
          let current_filter = table_filters[field.name || ""] || undefined;
          let _selectOption = select_options[field.name || ""];
          if (
            current_filter?.reference &&
            current_filter?.reference === _selectOption?.reference
          ) {
            return;
          }
          if (!current_filter && field.depends_on) {
            return;
          } else if (current_filter && field.depends_on) {
            if (
              !lodash.get(
                current_filter.filter,
                field.depends_on?.filter_key || ""
              )
            ) {
              return;
            }
          }

          await dispatch(request(current_filter?.filter)).unwrap();

          const state = store.getState();
          const options = selector(state);

          let result: any = {
            options: options.result.items,
            reference: current_filter?.reference || get_uuid(),
          };

          set_select_options((prev) => ({
            ...prev,
            [field.name || ""]: result,
          }));
        } catch (error) {
          console.error(`Failed to load options for ${field.name}:`, error);
        }
      });

    await Promise.all(promises);
  };

  useEffect(() => {
    load_filter_options();
  }, [table_filters]);

  function createJsonObject(key: string, value: any) {
    const obj = {};
    lodash.set(obj, key, value);
    return obj;
  }

  const transformFieldsToNestedObject = (inputObject: any) => {
    const result = {};

    Object.entries(inputObject).forEach(([key, value]) => {
      const keys = key.split(".");
      lodash.set(result, keys, value);
    });
    console.log("inputObject", inputObject, result);
    return result;
  };

  function get_uuid(buf: number = 0): string {
    const now: Date = new Date();
    const time: number = now.getTime();
    let offset: number = now.getTimezoneOffset();
    offset = offset * 60000;
    let dt: string = `${time - offset + buf}`;
    const uuid: string = `${dt.substr(0, 8)}-${dt.substr(8, 4)}-${dt.substr(
      12,
      1
    )}xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
      const r: number = (parseInt(dt, 10) + Math.random() * 16) % 16 | 0;
      dt = Math.floor(parseInt(dt, 10) / 16).toString();
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  const renderField = (field: DynamicFilterField) => {
    const { name, label, type, layout, depends_on, class_name } = field;
    const fieldProps = { name, label };
    let colSpan = 24;
    if (screens.xl) colSpan = layout?.xl || layout?.span || 24;
    if (screens.lg) colSpan = layout?.lg || layout?.xl || layout?.span || 24;
    if (screens.md)
      colSpan = layout?.md || layout?.lg || layout?.xl || layout?.span || 24;
    if (screens.sm)
      colSpan =
        layout?.sm ||
        layout?.md ||
        layout?.lg ||
        layout?.xl ||
        layout?.span ||
        24;
    if (screens.xs)
      colSpan =
        layout?.xs ||
        layout?.sm ||
        layout?.md ||
        layout?.lg ||
        layout?.xl ||
        layout?.span ||
        24;

    const disabled_date = (current_date: any) => {
      if (field.enabled_dates && field.enabled_dates.length > 0) {
        current_date = current_date ? current_date.format("YYYY-MM-DD") : null;
        return !field.enabled_dates.includes(current_date);
      }
      if (field.min_date && current_date.isBefore(field.min_date, "day")) {
        return true;
      }
      if (field.max_date && current_date.isAfter(field.max_date, "day")) {
        return true;
      }
      return false;
    };

    return (
      <Col span={colSpan} key={name}>
        <Form.Item
          {...fieldProps}
          colon={false}
          labelCol={{ ...field.label_col, style: { textAlign: "left" } }}
          wrapperCol={field.wrapper_col}
          className={class_name}
        >
          {type === "text" && <Input allowClear autoComplete="off" />}
          {type === "search" && <SearchBox />}
          {type === "number" && <InputNumber />}
          {type === "checkbox" && (
            <Checkbox.Group options={field.options}>
              {field.options?.map((option: any) => (
                <Checkbox
                  key={option[field.value_field || "id"]}
                  value={option[field.value_field || "id"]}
                >
                  {option[field.label_field || "name"]}
                </Checkbox>
              ))}
              {select_options[name || ""]?.options?.map((option: any) => (
                <Checkbox
                  key={option[field.value_field || "id"]}
                  value={option[field.value_field || "id"]}
                >
                  {option[field.label_field || "name"]}
                </Checkbox>
              ))}
            </Checkbox.Group>
          )}
          {type === "radio" && (
            <Radio.Group>
              {field.options?.map((option: any) => (
                <Radio
                  key={option[field.value_field || "id"]}
                  value={option[field.value_field || "id"]}
                >
                  {option[field.label_field || "name"]}
                </Radio>
              ))}
              {select_options[name || ""]?.options?.map((option: any) => (
                <Radio
                  key={option[field.value_field || "id"]}
                  value={option[field.value_field || "id"]}
                >
                  {option[field.label_field || "name"]}
                </Radio>
              ))}
            </Radio.Group>
          )}
          {type === "date" && <DatePicker disabledDate={disabled_date} />}
          {type === "range" && <RangePicker disabledDate={disabled_date} />}
          {type === "switch" && <Switch />}
          {type === "select" && (
            <Select
              onChange={(value) => handle_field_change(name || "", value)}
              disabled={
                depends_on &&
                !lodash.get(table_filters[name]?.filter, depends_on.filter_key)
              }
              filterOption={(input: any, option: any) => {
                console.log(option, input, "option");

                return (option?.children)
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              allowClear
              showSearch
            >
              {field.options?.map((option: any) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
              {select_options[name || ""]?.options?.map((option: any) => (
                <Option key={option.id} value={option.id}>
                  {option.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Col>
    );
  };

  const handle_field_change = (field_name: string, value: any) => {
    const dependant_fields = filters.filter(
      (f: any) => f.depends_on?.field === field_name
    );
    if (dependant_fields.length > 0) {
      let _filter = { ...table_filters };
      dependant_fields.forEach((dependant_field: any) => {
        if (dependant_field.depends_on) {
          const result = createJsonObject(
            dependant_field.depends_on.filter_key,
            value
          );
          if (!_filter[dependant_field.name]) {
            _filter[dependant_field.name] = {};
          }
          _filter[dependant_field.name].reference = get_uuid();
          _filter[dependant_field.name].filter = result;
          form.setFieldValue([dependant_field.name], null);
        }
      });
      set_table_filters(_filter);
    }
    form.setFieldValue([field_name], value);
    on_filter_change({ [field_name]: value }, form.getFieldsValue());
  };

  const on_filter_change = (value: any, values: any) => {
    for (const key in value) {
      let field: any = filters.find((x: any) => x.name === key);

      if (field.type === "text" || field.type === "number") {
      } else {
        if (field.type === "date") {
          console.log(values[field.value_field], "date_field");
          values[field.value_field] = values[field.value_field]
            ? `${moment(new Date(values[field.value_field])).format(
                "YYYY-MM-DD"
              )}T00:00:00.000Z`
            : "";
        }
        perform_change(values);
      }
    }
  };

  const handle_delayed_change = lodash.debounce((values) => {
    perform_change(values);
  }, 800);

  const perform_change = (values: any) => {
    let _params = { ...params };
    _params.filter = { ..._params.filter, ...values };
    // console.log("_params", _params, values, params);

    set_params(_params);
  };

  return (
    <Form form={form} onValuesChange={on_filter_change}>
      <Row gutter={16} justify={"end"} align={"middle"}>
        {filters.map(renderField)}
      </Row>
    </Form>
  );
};

export default FilterBox;
