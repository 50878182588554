import React, { useEffect, useState } from "react";
import {
  AppDispatch,
  update_product_list,
  update_product_list_clear,
  update_product_list_request,
} from "@src/redux";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

type Props = {
  product_lock_form: any;
  line_item?: any;
  onClose: Function;
  dispute_status?: string;
  lock_all_product?: any;
};

const UpdateProductLockModal = (props: Props) => {
  const { product_lock_form, onClose, lock_all_product } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();
  const [lock_product, set_lock_product] = useState(false);
  const [remainingChars, setRemainingChars] = useState(50);
  const [message, set_message] = useState<string>("");

  const { result, error, loading } = useSelector(update_product_list);

  useEffect(() => {
    if (result?.status === "success") {
      toast.success(
        !lock_all_product
          ? "All products have been locked"
          : "All products were unlocked"
      );
      dispatch(update_product_list_clear());
      onClose();
    } else if (error) {
      toast.error(error.message || "");
      dispatch(update_product_list_clear());
    }
    set_lock_product(lock_all_product);
  }, [result, error]);

  const handleSwitchChange = (checked: boolean) => {
    set_lock_product(checked);
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    set_message(text);
    if (text.length <= 50) {
      setRemainingChars(50 - text.length);
    }
  };

  const on_finish = (values: any) => {
    dispatch(
      update_product_list_request({
        filter: {
          is_locked: lock_all_product,
        },
        data: {
          is_locked: values?.is_locked,
          lock_message: message,
        },
      })
    );
  };

  return (
    <div>
      <Form form={product_lock_form} onFinish={on_finish} layout="vertical">
        <Form.Item
          name={"is_locked"}
          label={"Lock All Products"}
          rules={[
            {
              required: true,
              message: lock_all_product
                ? "Please unlock products."
                : "Please lock products.",
            },
          ]}
        >
          <Switch
            checked={lock_product}
            onChange={handleSwitchChange}
            size="default"
            style={{
              boxShadow: "none",
              outline: "none",
              backgroundColor: lock_product ? "green" : "red",
            }}
          />
        </Form.Item>
        <Form.Item
          name={"lock_message"}
          label={t("Products Lock Message")}
          rules={[
            {
              required: false,
              message: "Please enter a message.",
            },
          ]}
        >
          <TextArea
            disabled={lock_all_product}
            maxLength={50}
            onChange={handleTextAreaChange}
            style={{ width: "100%" }}
          />
          <div style={{ textAlign: "right", marginTop: 4 }}>
            {t("Remaining characters")}: {remainingChars}
          </div>
        </Form.Item>

        <Row justify={"end"} gutter={10}>
          <Col>
            <Button danger onClick={() => onClose()}>
              {t("close")}
            </Button>
          </Col>
          <Col>
            <Button htmlType="submit" type="primary" loading={loading}>
              {t("submit")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UpdateProductLockModal;
