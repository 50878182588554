import {
  get_customer_credit_list_request,
  get_customer_credit_list,
  get_customer_list_request,
  get_customer_list,
  update_customer_credit_request,
  update_customer_credit,
  create_customer_credit_request,
  create_customer_credit,
  delete_customer_credit_request,
  delete_customer_credit,
  get_customer_credit_request,
  get_customer_credit,
  get_order_payment_transaction_list_request,
  get_order_payment_transaction_list,
} from "@src/redux";
import React, { useRef, useState } from "react";
import DynamicTable, {
  DynamicTableColumn,
  DynamicTableHandle,
} from "../components/general/dynamic_table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DynamicForm, {
  DynamicFilterField,
  DynamicFormAction,
  DynamicFormField,
  DynamicFormSource,
} from "../components/general/dynamic_form";
import { Button, Col, Row, Typography } from "antd";
import { t } from "i18next";
import PageTitle from "../components/common/page_title";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useCheckPageComponentAccess } from "@src/hooks";
import { formatStatus } from "@src/helpers/status_constants";
const { Text } = Typography;

interface StatusOption {
  label: string;
  value: string;
}

const PaymentTransaction: React.FC = () => {
  const table_ref = useRef<DynamicTableHandle>(null);
  const [item_id, set_item_id] = useState(null);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [modal_type, set_modal_type] = useState<"add" | "edit">("add");

  const { t } = useTranslation();

  const form_fields: DynamicFormField[] = [
    {
      name: "customer_id",
      label: t("table:customer"),
      type: "select",
      rules: [{ required: true, message: "Please select Customer" }],
      source: {
        request: get_customer_list_request,
        selector: get_customer_list,
      },
      labelField: "name",
      valueField: "id",
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
    {
      name: "amount",
      label: t("table:credit_points"),
      type: "number",
      rules: [{ required: true, message: "Please enter Credit Points" }],
      className: "name-box",
      layout: { span: 24, xs: 24 },
      labelCol: { xs: 24, sm: 6, lg: 7 },
      wrapperCol: { xs: 24, sm: 18 },
    },
  ];

  const columns: DynamicTableColumn[] = [
    {
      title: "Customer Name",
      data_index: "order.customer.name",
      // sorter: true,
      // render: (record: any) => (
      //   <p style={{ color: "#19B1FF", cursor: "pointer" }}>{record}</p>
      // ),
    },
    {
      title: "Order Id",
      data_index: "order.reference",
      render: (record: string | any) => {
        console.log(typeof record);

        return typeof record === "string" && record !== "null" ? record : "-";
      },
    },
    {
      title: "Transaction Date",
      data_index: "initiated_date_time",
      sorter: true,
      render: (record: Date) => moment(record).format("DD/MM/YYYY,HH:mm"),
    },

    {
      title: "Transaction Id",
      data_index: "payment_txn_id",
      // sorter: true,
      render: (record: string | any) => {
        console.log(typeof record);

        return typeof record === "string" && record !== "null" ? record : "-";
      },
    },
    {
      title: "Payment Amount",
      data_index: "amount",
      sorter: true,
      render: (amount: any) => <Text>{amount.toFixed(2) || 0}</Text>,
    },
    {
      title: "Payment Transaction Status",
      data_index: "status",
      sorter: true,
      render: formatStatus,
    },
  ];
  const Status_options: StatusOption[] = [
    {
      label: "Failed",
      value: "failed",
    },
    // {
    //   label: "Initiated",
    //   value: "initiated",
    // },
    {
      label: "Success",
      value: "success",
    },
    {
      label: "Cancelled",
      value: "cancelled",
    },
  ];

  const filters: DynamicFilterField[] = [
    {
      name: "initiated_date_time",
      label: "Transaction date",
      type: "date",
      value_field: "initiated_date_time",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      class_name: "search-box",
      options: Status_options,
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
    {
      name: "search_string",
      label: " ",
      type: "search",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
  ];

  //   const form_source: DynamicFormSource = {
  //     request: get_customer_credit_request,
  //     selector: get_customer_credit,
  //     params: {
  //       filter: { id: item_id },
  //     },
  //   };

  //   const form_actions: DynamicFormAction[] = [
  //     {
  //       type: "reset",
  //       label: "Reset",
  //     },
  //     {
  //       type: "cancel",
  //       label: "Cancel",
  //     },
  //     {
  //       type: "submit",
  //       label: "Save",
  //       create_source: {
  //         request: create_customer_credit_request,
  //         selector: create_customer_credit,
  //         params: {
  //           data: {},
  //         },
  //         response_path: "result.status",
  //         response_success_value: "success",
  //         response_success_message: "Products created successfully",
  //         response_failure_message: "Products cannot be created",
  //       },
  //       update_source: {
  //         request: update_customer_credit_request,
  //         selector: update_customer_credit,
  //         params: {
  //           id: item_id || "",
  //           data: {},
  //         },
  //         response_path: "result.status",
  //         response_success_value: "success",
  //         response_success_message: "Products updated successfully",
  //         response_failure_message: "Products cannot be updated",
  //       },
  //     },
  //   ];

  function on_action(type: any, record?: any) {
    if (type === "edit") {
      set_item_id(record.id);
      set_modal_type(type);
      set_is_modal_open(true);
    } else {
      if (type === "add") {
        set_item_id(null);
        set_modal_type(type);
        set_is_modal_open(true);
      }
    }
  }

  function on_modal_action(type: any, values: any) {
    console.log("action:", type);
  }

  function close_modal(reload: boolean) {
    set_is_modal_open(false);
    set_item_id(null);
    set_modal_type("add");
    if (reload && table_ref.current) {
      table_ref.current.load_data();
    }
  }

  return (
    <>
      <div className="product-page-title-container">
        <div className="product-page-title">
          <PageTitle is_color={true} title={t("payment_transaction")} />
        </div>

        {/* <div className="add-drivers-button">
          {useCheckPageComponentAccess("add_credit_button") && (
            <Button type="primary" onClick={() => on_action("add")}>
              {t("add_credit")}
            </Button>
          )}
        </div> */}
      </div>
      <br />
      <DynamicTable
        ref={table_ref}
        columns={columns}
        filters={filters}
        source={{
          request: get_order_payment_transaction_list_request,
          selector: get_order_payment_transaction_list,
          params: {
            filter: {},
            sort: { field: "initiated_date_time", order: "desc" },
          },
        }}
        pagination={{
          page_size: 10,
          show_total: "Total {total} Payment Transactions",
        }}
        on_action={on_action}
      />
      {/* <DynamicForm
        view_type="modal"
        type={modal_type}
        modal_title={modal_type === "add" ? t("add_credit") : t("edit_product")}
        is_modal_open={is_modal_open}
        close_modal={close_modal}
        source={form_source}
        fields={form_fields}
        actions={form_actions}
        on_action={on_modal_action}
      /> */}
    </>
  );
};

export default PaymentTransaction;
