import React, { useEffect } from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Upload,
  Select,
  TimePicker,
  InputNumber,
  Space,
  Row,
  Col,
} from "antd";
import { FormInstance } from "antd/lib";
import {
  action,
  handle_before_upload,
  handle_file_changed,
  handle_upload,
} from "@src/helpers/file_upload";
import {
  AppDispatch,
  get_category_list,
  get_category_list_request,
  get_gst_list,
  get_gst_list_request,
} from "@src/redux";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";

const { TextArea } = Input;
const { Option } = Select;

interface CreateProductFormProps {
  form: FormInstance<any>;
  close_modal: any;
  handle_reset: () => void;
  handle_submit: any;
  update_product_loading: boolean;
  create_product_loading: boolean;
}
interface MeasurementType {
  label: string;
  value: any;
}

const CreateProductForm: React.FC<CreateProductFormProps> = ({
  form,
  close_modal,
  handle_reset,
  handle_submit,
  update_product_loading,
  create_product_loading,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const file_list = Form.useWatch("image_list", form) || [];
  const { result: category_list_result } = useSelector(get_category_list);
  const { result: gst_list_result } = useSelector(get_gst_list);

  const measurement_type: MeasurementType[] = [
    {
      value: "piece",
      label: "Pcs",
    },
    {
      value: "gram",
      label: "Gram",
    },
    {
      value: "kilogram",
      label: "Kilogram",
    },
    {
      value: "litre",
      label: "Litre",
    },
    {
      value: "millilitre",
      label: "Millilitre",
    },
    {
      value: "bag",
      label: "Bag",
    },
    {
      value: "box",
      label: "Box",
    },
    {
      value: "packet",
      label: "Pocket",
    },
    {
      value: "case",
      label: "Case",
    },
    {
      value: "roll",
      label: "Roll",
    },
  ];

  useEffect(() => {
    dispatch(get_category_list_request({}));
    dispatch(get_gst_list_request({}));
  }, [dispatch]);

  const upload_file_list = Array.isArray(file_list)
    ? file_list
    : file_list.fileList || [];

  console.log(upload_file_list, "updatedFileList");

  const handleRemove = (file: any) => {
    const updatedFileList = file_list?.fileList?.filter(
      (item: any) => item.uid !== file.uid
    );

    form.setFieldsValue({
      image_list: updatedFileList,
    });
  };

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 13 }}
        layout="horizontal"
        style={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 25,
        }}
        onFinish={handle_submit}
      >
        {/* <Row justify={"end"}> */}
        {/* <Col span={15}> */}
        <Form.Item
          name="image_list"
          required={true}
          label="Image"
          rules={[{ required: true, message: "Please upload image." }]}
        >
          <Upload
            maxCount={1}
            action={action}
            fileList={upload_file_list}
            listType="picture-card"
            showUploadList={{
              showDownloadIcon: false,
              showPreviewIcon: true,
            }}
            customRequest={(e: any) => handle_upload(e)}
            beforeUpload={handle_before_upload}
            onChange={handle_file_changed}
            onRemove={handleRemove}
          >
            {upload_file_list?.length === 1 ? null : (
              <Button
                style={{
                  width: "100%",
                  height: "100%",
                }}
                icon={<UploadOutlined />}
              >
                Upload
              </Button>
            )}
          </Upload>
        </Form.Item>
        {/* </Col> */}
        {/* </Row> */}

        <Form.Item
          label="Name"
          name="name"
          required={true}
          rules={[
            { required: true, message: "Please enter Product's name" },
            {
              pattern: /^[a-zA-Z0-9 \-]+$/,
              message: "Only letters and numbers allowed.",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Category"
          name="category_id"
          required={true}
          rules={[{ required: true, message: "Please select a category." }]}
        >
          <Select
            placeholder="Select a category"
            filterOption={(input: any, option: any) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            allowClear
            showSearch
          >
            {category_list_result?.items?.map((item: any) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="HSN Code"
          name="hsn_code"
          required={true}
          rules={[
            { required: true, message: "Please enter hsn code" },
            {
              pattern: /^\d{3}|\d{6}|\d{8}$/,
              message: "Please enter valid hsn code",
            },
          ]}
        >
          <Input min={3} max={8} />
        </Form.Item>
        <Form.Item
          label="Orderable Time Range"
          name="orderable_time_range"
          required={true}
          rules={[
            // { required: true, message: "Please select a time range." },
            {
              validator: async (_, value) => {
                if (!value || value.length !== 2) {
                  return Promise.reject(
                    new Error("Please select a valid time range.")
                  );
                }

                const [start, end] = value.map((time: string) => dayjs(time));

                if (!start || !end) {
                  return Promise.reject(
                    new Error("Both start and end times are required.")
                  );
                }

                if (start.isAfter(end)) {
                  return Promise.reject(
                    new Error("Start time must be earlier than end time.")
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <TimePicker.RangePicker format="HH:mm" />
        </Form.Item>

        <Form.Item
          label="Orderable Dates"
          required
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(
                    new Error("Orderable dates is required.")
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Form.List
            name={"orderable_dates"}
            initialValue={[{ from: 1, to: 31 }]}
          >
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map(
                    ({ key, name, ...restField }, index, allFields) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "from"]}
                          rules={[
                            // {
                            //   required: true,
                            //   message: "From day is required",
                            // },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const allValues =
                                  getFieldValue("orderable_dates");
                                console.log(allValues, "allValues", value);

                                // const toValue = getFieldValue([name, "to"]);
                                // const fromValues = allValues.map(
                                //   (item) => item.from
                                // );

                                // if (value >= toValue) {
                                //   return Promise.reject(
                                //     new Error(
                                //       "From day must be less than To day"
                                //     )
                                //   );
                                // }
                                // if (
                                //   fromValues.filter((v) => v === value).length >
                                //   1
                                // ) {
                                //   return Promise.reject(
                                //     new Error(
                                //       "Duplicate 'From' day is not allowed"
                                //     )
                                //   );
                                if (!value) {
                                  return Promise.reject(
                                    new Error("from day is required")
                                  );
                                }
                                if (value <= 0) {
                                  return Promise.reject(
                                    new Error("Form day must be greater than 0")
                                  );
                                }
                                if (value > 30) {
                                  return Promise.reject(
                                    new Error("From day must be less than 31")
                                  );
                                }

                                return Promise.resolve();
                              },
                            }),
                          ]}
                          style={{ flex: 1 }}
                        >
                          <InputNumber
                            placeholder="From"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "to"]}
                          rules={[
                            // {
                            //   required: true,
                            //   message: "To day is required",
                            // },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const allValues =
                                  getFieldValue("orderable_dates");
                                console.log(allValues, "allValues", value);

                                // const fromValue = getFieldValue([name, "from"]);
                                // const toValues = allValues.map(
                                //   (item) => item.to
                                // );
                                // if (toValues > 31) {
                                //   return Promise.reject(
                                //     new Error("To day must be less than 31")
                                //   );
                                // }
                                // if (value <= fromValue) {
                                //   return Promise.reject(
                                //     new Error(
                                //       "To day must be greater than From day"
                                //     )
                                //   );
                                // }
                                // if (
                                //   toValues.filter((v) => v === value).length > 1
                                // ) {
                                //   return Promise.reject(
                                //     new Error(
                                //       "Duplicate 'To' day is not allowed"
                                //     )
                                //   );
                                // }
                                if (!value) {
                                  return Promise.reject(
                                    new Error("To day is required")
                                  );
                                }
                                if (value > 31) {
                                  return Promise.reject(
                                    new Error("To day must be less than 31")
                                  );
                                }
                                if (value < 2) {
                                  return Promise.reject(
                                    new Error("To day must be greater than 1")
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                          style={{ flex: 1 }}
                        >
                          <InputNumber
                            placeholder="To"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          onClick={() => remove(name)}
                          style={{
                            fontSize: "20px",
                            width: "30px",
                            textAlign: "center",
                          }}
                        />
                      </Space>
                    )
                  )}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add ranges between (1 to 31)
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </Form.Item>
        <Form.Item
          label="Price"
          name="price"
          required={true}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(new Error("Please enter price"));
                } else if (value <= 0) {
                  return Promise.reject(
                    new Error("Please enter a valid price")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea />
        </Form.Item>
        <Form.Item
          label="Measurement Type"
          name="measurement_type"
          required={true}
          rules={[
            { required: true, message: "Please select measurement type" },
          ]}
        >
          <Select
            placeholder="Select measurement type"
            filterOption={(input: any, option: any) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            allowClear
            showSearch
          >
            {measurement_type?.map((item: any) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="GST"
          name="gst_id"
          required={true}
          rules={[{ required: true, message: "Please select GST" }]}
        >
          <Select placeholder="Select GST">
            {gst_list_result?.items?.map((item: any) => (
              <Option key={item.id} value={item.id}>
                {item.percentage}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Minimum Order Quantity"
          name="minimum_order_quantity"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value === undefined || value === null || value === "") {
                  return Promise.resolve();
                }
                if (value <= 0) {
                  return Promise.reject(
                    new Error("Please enter a valid minimum order quantity")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          label="Minimum Order Amount"
          name="minimum_order_amount"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value === undefined || value === null || value === "") {
                  return Promise.resolve();
                }
                if (value <= 0) {
                  return Promise.reject(
                    new Error("Please enter a valid minimum order amount")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Row justify={"end"}>
          <Col>
            <Form.Item>
              <Space>
                <Button key="reset" onClick={handle_reset}>
                  Reset
                </Button>
                <Button key="cancel" danger onClick={close_modal}>
                  Cancel
                </Button>
                <Button
                  key="submit"
                  type="primary"
                  htmlType="submit"
                  loading={create_product_loading || update_product_loading}
                >
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateProductForm;
