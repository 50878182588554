import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Space,
  Button,
  Typography,
  DatePicker,
  Select,
  SelectProps,
  Modal,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import SearchBox from "../common/search_box";
import PageTitle from "../common/page_title";
import { useTranslation } from "react-i18next";
import { formatStatus } from "@src/helpers/status_constants";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  create_order,
  create_order_clear,
  create_order_request,
  get_category_list,
  get_category_list_request,
  get_customer_list,
  get_customer_list_request,
  get_order,
  get_order_list,
  get_order_list_request,
  get_order_request,
  get_product_list,
  get_product_list_request,
} from "@src/redux";
import DynamicForm, {
  DynamicFilterField,
  DynamicFormAction,
  DynamicFormField,
  DynamicFormSource,
} from "../general/dynamic_form";
import CustomerOrderForm from "./customer_order_form";
import CommonModal from "../common/modal/custom_modal";
import DynamicTable, {
  DynamicTableColumn,
  DynamicTableHandle,
} from "../general/dynamic_table";
import moment from "moment";
import { useCheckPageComponentAccess } from "@src/hooks";
import { retrieve_item } from "@src/helpers/functions";
import { indian_currency_format } from "@src/helpers/constants";
import toast from "react-hot-toast";
import { LuMilk, LuMilkOff } from "react-icons/lu";

const { Text } = Typography;

interface OrderData {
  key: string;
  orderId: string;
  customerName: string;
  totalAmount: string;
  mobile: Number;
  status: String;
}

interface LocationOption {
  label: string;
  value: string;
}

const CustomerOrderList: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: customer_id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const table_ref = useRef<DynamicTableHandle>(null);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const { result: get_orders, loading: get_order_loading } =
    useSelector(get_order_list);
  const { result: create_order_result, error: create_order_error } =
    useSelector(create_order);
  const { result: product_list_items, loading: product_list_loading } =
    useSelector(get_product_list);
  // const columns = [
  //   {
  //     title: t("table:order_id"),
  //     dataIndex: "ordered_datetime",
  //     key: "orderId",
  //     render:()=>
  //   },
  //   {
  //     title: t("table:name"),
  //     dataIndex: "customerName",
  //     key: "customerName",
  //   },
  //   {
  //     title: t("table:mobile"),
  //     dataIndex: "mobile",
  //     key: "mobile",
  //   },
  //   {
  //     title: t("table:amount"),
  //     dataIndex: "totalAmount",
  //     key: "totalAmount",
  //   },
  //   {
  //     title: t("table:status"),
  //     dataIndex: "status",
  //     key: "status",
  //     render: formatStatus,
  //   },
  //   {
  //     title: t("table:action"),
  //     key: "action",
  //     render: (text: any, record: OrderData) => (
  //       <Space size="middle">
  //         <FaEye
  //           className="view-icon"
  //           onClick={() => navigate(`/admin/customer-order/${record.orderId}`)}
  //         />
  //       </Space>
  //     ),
  //   },
  // ];

  const columns: DynamicTableColumn[] = [
    {
      title: t("table:order_id"),
      sorter: true,
      sorted_by: "reference",
      fixed: "left",
      render: (record: any) => (
        <Link to={`/admin/customer-order/${record?.id}`}>
          {record?.reference}
        </Link>
      ),
    },
    {
      title: t("table:ordered_datetime"),
      data_index: "ordered_datetime",
      sorter: true,
      render: (record: Date) => moment(record).format("DD/MM/YYYY hh:mm a"),
    },
    {
      title: t("table:name"),
      data_index: "customer.name",
      // sorter: true,
    },
    {
      title: t("table:outlet"),
      data_index: "franchise.name",
      // sorter: true,
    },

    {
      title: t("table:total_products"),
      data_index: "line_item_list",
      // sorter: true,
      fixed: "left",
      render: (total_products: any) => (
        <Text>{total_products?.length || 0}</Text>
      ),
    },
    {
      title: t("table:milk_in_order"),
      data_index: "is_milk_in_line_item",
      sorter: true,
      render: (record: boolean) =>
        record ? (
          <>
            <LuMilk color="green" size={20} />
          </>
        ) : (
          <>
            <LuMilkOff color="red" size={20} />
          </>
        ),
    },
    {
      title: t("table:payment_status"),
      data_index: "payment_status",
      sorter: true,
      render: formatStatus,
    },
    {
      title: t("table:credit_used"),
      data_index: "credits_used",
      sorter: true,
      render: (credit_used: any) => (
        <Text>{indian_currency_format(credit_used) || 0}</Text>
      ),
    },
    {
      title: t("table:total_amount"),
      data_index: "total_amount",
      sorter: true,
      render: (total_amount: any) => (
        <Text>{total_amount.toFixed(2) || 0}</Text>
      ),
    },
    {
      title: t("table:status"),
      data_index: "status",
      sorter: true,
      fixed: "right",
      render: formatStatus,
    },
    // {
    //   title: "Actions",
    //   fixed: "right",
    //   actions: [
    //     {
    //       type: "view",
    //       icon: <FaEye />,
    //     },
    //   ],
    // },
  ];
  const Status_options: LocationOption[] = [
    {
      label: "Placed",
      value: "placed",
    },
    {
      label: "In Progress",
      value: "in_progress",
    },
    {
      label: "Delivered",
      value: "delivered",
    },
    // {
    //   label: "Cancelled",
    //   value: "cancelled",
    // },
  ];
  const filters: DynamicFilterField[] = [
    {
      name: "ordered_datetime",
      label: "Ordered date",
      type: "date",
      value_field: "ordered_datetime",
      class_name: "search-box",
      layout: { span: 6, xs: 6, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
    {
      name: "customer_id",
      label: "Customer",
      type: "select",
      source: {
        request: get_customer_list_request,
        selector: get_customer_list,
      },
      value_field: "customer_id",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      class_name: "search-box",
      options: Status_options,
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
    {
      name: "search_string",
      label: " ",
      type: "search",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
  ];

  useEffect(() => {
    if (create_order_result?.status === "success") {
      toast.success(`Order placed successfully`);
      if (table_ref.current) {
        table_ref.current.load_data();
      }
      dispatch(get_order_list_request({}));
      dispatch(create_order_clear());
      set_is_modal_open(false);
    } else if (create_order_error) {
      toast.error(create_order_error.message || "Failed to create order");
      if (table_ref.current) {
        table_ref.current.load_data();
      }
      dispatch(get_order_list_request({}));
      dispatch(create_order_clear());
      set_is_modal_open(false);
    }
    if (create_order_result?.error?.status_code === "400") {
      let product_id = create_order_result?.error?.message?.split(":")?.[1];
      let available_quantity =
        create_order_result?.error?.message?.split(":")?.[2];
      if (product_list_items?.items && product_list_items?.items.length > 0) {
        let product = product_list_items?.items?.find(
          (product: any) => product?.id === product_id
        );
        if (available_quantity && Number(available_quantity) > 0) {
          toast.error(
            `There are only ${available_quantity} ${product?.name} in stock`
          );
        } else {
          toast.error(`${product?.name} is out of stock`);
        }
        dispatch(create_order_clear());
      }
    }
  }, [create_order_result, create_order_error]);
  useEffect(() => {
    dispatch(get_product_list_request({}));
  }, []);

  function close_modal() {
    set_is_modal_open(false);
  }
  function on_action(type: any, record?: any) {
    if (type === "view") {
      navigate(`/admin/customer-order/${record.id}`);
    } else {
      set_is_modal_open(true);
    }
  }

  return (
    <div style={{ padding: "0px" }}>
      <div className="farmer-list-filter-container">
        <PageTitle is_color={true} title={t("customer_orders")} />
        {useCheckPageComponentAccess("create_order_button") && (
          <Button type="primary" onClick={() => on_action("add")}>
            {t("create_order")}
          </Button>
        )}
      </div>

      {/* <Table<any>
        columns={columns}
        dataSource={get_orders?.items || []}
        className="admin-table"
        loading={get_order_loading}
      /> */}
      <DynamicTable
        ref={table_ref}
        columns={columns}
        filters={filters}
        source={{
          request: get_order_list_request,
          selector: get_order_list,
          params: {
            filter: {},
            sort: [],
          },
        }}
        pagination={{
          page_size: 10,
          page_number: 1,
          show_total: "Total {total} Customer Orders",
        }}
        on_action={on_action}
      />
      <CommonModal
        open={is_modal_open}
        onCancel={close_modal}
        title={t("create_customer_order")}
        width={700}
      >
        <CustomerOrderForm onCancel={close_modal} />
      </CommonModal>
    </div>
  );
};

export default CustomerOrderList;
