import { combineReducers } from '@reduxjs/toolkit';
import delete_brand_fee_reducer from './delete_brand_fee';
import get_brand_fee_reducer from './get_brand_fee';
import update_brand_fee_reducer from './update_brand_fee';

const brand_fee_reducer = combineReducers({
  delete_brand_fee: delete_brand_fee_reducer,
  get_brand_fee: get_brand_fee_reducer,
  update_brand_fee: update_brand_fee_reducer,
});

export default brand_fee_reducer;
