import React, { useEffect, useRef, useState } from "react";
import { Typography } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import SearchBox from "../common/search_box";
import PageTitle from "../common/page_title";
import { useTranslation } from "react-i18next";
import { formatStatus } from "@src/helpers/status_constants";
import { useDispatch, useSelector } from "react-redux";
import {
  AppDispatch,
  create_dispute_clear,
  create_order,
  get_customer_list,
  get_customer_list_request,
  get_dispute_list,
  get_dispute_list_request,
} from "@src/redux";
import { DynamicFilterField } from "../general/dynamic_form";
// import CustomerOrderForm from "./customer_order_form";
import CommonModal from "../common/modal/custom_modal";
import DynamicTable, {
  DynamicTableColumn,
  DynamicTableHandle,
} from "../general/dynamic_table";
import moment from "moment";
import { indian_currency_format } from "@src/helpers/constants";
const { Text } = Typography;
interface OrderData {
  key: string;
  orderId: string;
  customerName: string;
  totalAmount: string;
  mobile: Number;
  status: String;
}

interface LocationOption {
  label: string;
  value: string;
}

const CustomerReturnList: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const table_ref = useRef<DynamicTableHandle>(null);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const { result: create_order_result } = useSelector(create_order);
  // const columns = [
  //   {
  //     title: t("table:order_id"),
  //     dataIndex: "ordered_datetime",
  //     key: "orderId",
  //     render:()=>
  //   },
  //   {
  //     title: t("table:name"),
  //     dataIndex: "customerName",
  //     key: "customerName",
  //   },
  //   {
  //     title: t("table:mobile"),
  //     dataIndex: "mobile",
  //     key: "mobile",
  //   },
  //   {
  //     title: t("table:amount"),
  //     dataIndex: "totalAmount",
  //     key: "totalAmount",
  //   },
  //   {
  //     title: t("table:status"),
  //     dataIndex: "status",
  //     key: "status",
  //     render: formatStatus,
  //   },
  //   {
  //     title: t("table:action"),
  //     key: "action",
  //     render: (text: any, record: OrderData) => (
  //       <Space size="middle">
  //         <FaEye
  //           className="view-icon"
  //           onClick={() => navigate(`/admin/customer-order/${record.orderId}`)}
  //         />
  //       </Space>
  //     ),
  //   },
  // ];

  const columns: DynamicTableColumn[] = [
    {
      title: t("table:return_id"),
      sorter: true,
      sorted_by: "reference",
      render: (record: any) => (
        <Link to={`/admin/customer-return/${record.id}`}>
          {record?.reference}
        </Link>
      ),
    },
    {
      title: t("table:order_id"),
      sorter: false,
      // sorted_by: "reference",
      render: (record: any) => (
        <Link to={`/admin/customer-order/${record.order.id}`}>
          {record?.order.reference}
        </Link>
      ),
    },
    {
      title: t("table:name"),
      data_index: "customer.name",
      // sorter: true,
    },
    {
      title: t("table:returned_datetime"),
      data_index: "created_datetime",
      sorter: true,
      render: (record: Date) => moment(record).format("DD/MM/YYYY hh:mm a"),
    },
    {
      title: t("table:outlet"),
      data_index: "franchise.name",
      // sorter: true,
    },
    {
      title: t("table:total_products"),
      data_index: "line_item_list",
      // sorter: true,
      fixed: "left",
      render: (total_products: any) => (
        <Text>{total_products?.length || 0}</Text>
      ),
    },
    {
      title: t("table:total_value"),
      data_index: "total_value",
      sorter: true,
      render: (total_value: number) => indian_currency_format(total_value),
    },
    {
      title: t("table:status"),
      data_index: "status",
      sorter: true,
      render: formatStatus,
    },
  ];
  const Status_options: LocationOption[] = [
    {
      label: "Submitted",
      value: "submitted",
    },
    {
      label: "Accepted",
      value: "accepted",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];
  const filters: DynamicFilterField[] = [
    {
      name: "created_datetime",
      label: "Returned date",
      type: "date",
      value_field: "created_datetime",
      class_name: "search-box",
      layout: { span: 6, xs: 6, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
    {
      name: "customer_id",
      label: "Customer",
      type: "select",
      source: {
        request: get_customer_list_request,
        selector: get_customer_list,
      },
      value_field: "customer_id",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      class_name: "search-box",
      options: Status_options,
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
    {
      name: "search_string",
      label: " ",
      type: "search",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
  ];

  useEffect(() => {
    if (create_order_result?.status === "success") {
      if (table_ref.current) {
        table_ref.current.load_data();
      }
      dispatch(create_dispute_clear());
      set_is_modal_open(false);
    }
  }, [create_order_result]);

  function close_modal() {
    set_is_modal_open(false);
  }
  function on_action(type: any, record?: any) {
    if (type === "view") {
      navigate(`/admin/customer-return/${record.id}`);
    } else {
      set_is_modal_open(true);
    }
  }

  return (
    <div style={{ padding: "0px" }}>
      <div className="farmer-list-filter-container">
        <PageTitle is_color={true} title={t("customer_return")} />
        {/* <Button type="primary" onClick={() => on_action("add")}>
          {t("create_order")}
        </Button> */}
      </div>

      {/* <Table<any>
        columns={columns}
        dataSource={get_orders?.items || []}
        className="admin-table"
        loading={get_order_loading}
      /> */}
      <DynamicTable
        ref={table_ref}
        columns={columns}
        filters={filters}
        source={{
          request: get_dispute_list_request,
          selector: get_dispute_list,
          params: {
            sort: { field: "created_datetime", order: "desc" },
          },
        }}
        pagination={{
          page_size: 10,
          page_number: 1,
          show_total: "Total {total} Return Orders",
        }}
        on_action={on_action}
      />
      <CommonModal
        open={is_modal_open}
        onCancel={close_modal}
        title={t("create_return_order")}
        width={700}
      >
        {/* <CustomerOrderForm onCancel={close_modal} /> */}
      </CommonModal>
    </div>
  );
};

export default CustomerReturnList;
