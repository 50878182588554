import { Route } from "@src/redux";
import CustomerOrderDetail from "@src/views/components/customer_order/customer_order_detail";
import CustomerOrderList from "@src/views/components/customer_order/customer_order_list";
import AdminLayout from "@src/views/layouts/admin_layout";
import Category from "@src/views/pages/category";
import CustomerDetail from "@src/views/pages/customer_detail";
import Customers from "@src/views/pages/customers";
import AdminLogin from "@src/views/pages/admin_login";
import CustomerCredits from "@src/views/pages/customer_credits";
import Products from "@src/views/pages/products";
import AccessRights from "@src/views/pages/access_right";
import StockManagement from "@src/views/pages/stock_management";
import ReturnOrders from "@src/views/pages/return_orders";
import CustomerReturnDetail from "@src/views/components/customer_return/customer_return_detail";
import ProductGST from "@src/views/pages/product_gst";
import PaymentTransaction from "@src/views/pages/payment_transaction";
import ForgetPassword from "@src/views/pages/forget_password";
import Brands from "@src/views/pages/brands";
import StockLog from "@src/views/pages/stock_log";
import BrandFee from "@src/views/pages/brand_fee";

const my_routes: Route[] = [
  {
    path: "/admin",
    name: "Brands",
    authenticate: false,
    component: <Brands />,
  },
  {
    path: "/admin/login",
    name: "Login",
    authenticate: false,
    component: <AdminLogin />,
  },
  {
    path: "/admin/forget-password",
    name: "Forget Password",
    authenticate: false,
    component: <ForgetPassword />,
    children: [],
  },
  {
    path: "/admin",
    name: "Admin",
    authenticate: false,
    component: <AdminLayout />,
    children: [
      {
        path: "access-rights",
        name: "Access Rights",
        authenticate: true,
        component: <AccessRights />,
        children: [],
      },
      {
        path: "stock-management",
        name: "Stock Management",
        authenticate: true,
        component: <StockManagement />,
        children: [],
      },
      {
        path: "return-orders",
        name: "Return Order",
        authenticate: true,
        component: <ReturnOrders />,
        children: [],
      },
      {
        path: "customer-return/:id?",
        name: "Return Order Detail",
        authenticate: true,
        component: <CustomerReturnDetail />,
      },
      {
        path: "products",
        name: "Products",
        authenticate: true,
        component: <Products />,
      },
      {
        path: "products/product-log/:id?",
        name: "Product Stock Log",
        authenticate: false,
        component: <StockLog />,
      },
      {
        path: "customer-credits",
        name: "Customer Credits",
        authenticate: true,
        component: <CustomerCredits />,
      },
      {
        path: "customer-orders",
        name: "Customer Orders",
        authenticate: true,
        component: <CustomerOrderList />,
      },
      // {
      //   path: "customer-orders/:id?",
      //   name: "Customer Orders",
      //   authenticate: true,
      //   component: <CustomerOrderList />,
      // },
      {
        path: "customer-order/:id?",
        name: "Customer Order Detail",
        authenticate: true,
        component: <CustomerOrderDetail />,
      },
      {
        path: "categories",
        name: "Categories",
        authenticate: true,
        component: <Category />,
        children: [],
      },
      {
        path: "customers",
        name: "Customers",
        authenticate: true,
        component: <Customers />,
        children: [],
      },
      {
        path: "customer/:id?",
        name: "Customer Details",
        authenticate: true,
        component: <CustomerDetail />,
        children: [],
      },
      {
        path: "customer-credits",
        name: "Customer credits",
        authenticate: true,
        component: <CustomerCredits />,
        children: [],
      },
      {
        path: "product-gst",
        name: "Product GST",
        authenticate: true,
        component: <ProductGST />,
        children: [],
      },
      {
        path: "payment-transaction",
        name: "Payment Transaction",
        authenticate: true,
        component: <PaymentTransaction />,
        children: [],
      },
      {
        path: "brand-fee",
        name: "Brand Fee",
        authenticate: true,
        component: <BrandFee />,
        children: [],
      },
    ],
  },
];

export default my_routes;
