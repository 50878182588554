import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";
import { RootState } from "@src/redux/store";
import * as TYPES from "@src/redux/types";

const GRAPHQL = gql`
  query get_customer_credit_list(
    $filter: customer_credit_filter_input
    $pagination: pagination_input
    $sort: [sort_input]
  ) {
    get_customer_credit_list(
      filter: $filter
      pagination: $pagination
      sort: $sort
    ) {
      items {
        customer {
          id
          name
          mobile
          email
          address
          credits
        }
        type
        credited_by_user_id
        created_datetime
        amount
        id
      }
      pagination {
        total_count
        page_limit
        page_number
      }
      error {
        status_code
        message
      }
    }
  }
`;

const initial_state: TYPES.CustomerCreditListState = {
  result: null,
  error: null,
  loading: false,
};

export const get_customer_credit_list_request = createAsyncThunk<
  TYPES.CustomerCreditList,
  {
    filter?: TYPES.CustomerCreditFilterInput;
    pagination?: TYPES.PaginationInput;
    sort?: TYPES.SortInput[];
  },
  { extra: ApolloClient<NormalizedCacheObject> }
>(
  "customer_credit/get_customer_credit_list",
  async (
    params: {
      filter?: TYPES.CustomerCreditFilterInput;
      pagination?: TYPES.PaginationInput;
      sort?: TYPES.SortInput[];
    },
    { extra: client }
  ) => {
    try {
      const response = await client.query({
        query: GRAPHQL,
        variables: params,
      });
      return response.data.get_customer_credit_list;
    } catch (error) {
      const err = error as { message?: String; status_code?: String };
      return Promise.reject({
        message: err.message || "An error occurred",
        status_code: err.status_code || "500",
      });
    }
  }
);

const get_customer_credit_list_slice = createSlice({
  name: "get_customer_credit_list",
  initialState: initial_state,
  reducers: {
    get_customer_credit_list_clear: (state) => {
      state.result = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_customer_credit_list_request.pending, (state) => {
        state.result = null;
        state.error = null;
        state.loading = true;
      })
      .addCase(get_customer_credit_list_request.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(get_customer_credit_list_request.rejected, (state, action) => {
        state.error = (action.payload as Error) || {
          message: "Failed to fetch",
          status_code: "500",
        };
        state.loading = false;
      });
  },
});

export const { get_customer_credit_list_clear } =
  get_customer_credit_list_slice.actions;

export const get_customer_credit_list = createSelector(
  (state: RootState) => state.customer_credit.get_customer_credit_list.result,
  (state: RootState) => state.customer_credit.get_customer_credit_list.error,
  (state: RootState) => state.customer_credit.get_customer_credit_list.loading,
  (result, error, loading) => ({
    result,
    error,
    loading,
  })
);

export default get_customer_credit_list_slice.reducer;
