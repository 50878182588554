import {
  create_product,
  update_product_request,
  update_product,
  delete_product,
  delete_product_request,
  get_product_list,
  get_product_list_request,
  create_product_request,
  get_category_list_request,
  get_category_list,
  get_product_request,
  get_product,
  get_gst_list_request,
  get_gst_list,
  AppDispatch,
  update_product_clear,
  get_category,
  get_customer_list_request,
  get_customer_list,
  create_product_clear,
} from "@src/redux";
import React, { useEffect, useRef, useState } from "react";
import DynamicTable, {
  DynamicTableColumn,
  DynamicTableHandle,
} from "../components/general/dynamic_table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DynamicForm, {
  DynamicFilterField,
  DynamicFormAction,
  DynamicFormField,
  DynamicFormSource,
} from "../components/general/dynamic_form";
import { Button, Col, Modal, Row, Switch } from "antd";
import { t } from "i18next";
import PageTitle from "../components/common/page_title";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FaLock, FaUnlock } from "react-icons/fa";
import { useCheckPageComponentAccess } from "@src/hooks";
import { Form, InputNumber, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { DEFAULT_IMAGE } from "@src/helpers/images_constants";
import { Link } from "react-router-dom";
import CommonModal from "../components/common/modal/custom_modal";
import ModalTitle from "../components/common/modal/modal_title";
import { AiFillCloseCircle } from "react-icons/ai";
import CreateProductForm from "../components/products/create_product_form";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import UpdateOrderPaymentModal from "../components/customer_order/order_line_item/update_order_payment_modal";
import UpdateProductLockModal from "../components/products/update_product_lock_modal";

interface MeasurementType {
  label: string;
  value: any;
}

interface DayRange {
  from: number;
  to: number;
}

const Products: React.FC = () => {
  const table_ref = useRef<DynamicTableHandle>(null);
  const [form] = Form.useForm();
  const [product_lock_form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [item_id, set_item_id] = useState(null);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [modal_type, set_modal_type] = useState<"add" | "edit">("add");
  const [gst_option, set_gst_option] = useState<any>([]);
  const [lock_all_product, set_lock_all_product] = useState<Boolean>(false);
  const [lock_modal, set_lock_modal] = useState<Boolean>(false);
  const [lock_status, set_lock_status] = useState<"locked" | "un_locked">();
  const { t } = useTranslation();
  const { result: gst_list } = useSelector(get_gst_list);
  const { result: get_product_list_result } = useSelector(get_product_list);
  const {
    result: update_product_result,
    loading: update_product_loading,
    error: create_product_error,
  } = useSelector(update_product);
  const {
    result: create_product_result,
    loading: create_product_loading,
    error: update_product_error,
  } = useSelector(create_product);
  const { result: product_result } = useSelector(get_product);

  const measurement_type: MeasurementType[] = [
    {
      value: "piece",
      label: "Pcs",
    },
    {
      value: "gram",
      label: "Gram",
    },
    {
      value: "kilogram",
      label: "Kilogram",
    },
    {
      value: "litre",
      label: "Litre",
    },
    {
      value: "millilitre",
      label: "Millilitre",
    },
    {
      value: "bag",
      label: "Bag",
    },
    {
      value: "box",
      label: "Box",
    },
    {
      value: "packet",
      label: "Pocket",
    },
    {
      value: "case",
      label: "Case",
    },
    {
      value: "roll",
      label: "Roll",
    },
  ];
  useEffect(() => {
    dispatch(get_gst_list_request({}));
  }, []);

  useEffect(() => {
    if (gst_list) {
      const gst_item = gst_list?.items?.map((gst: any) => ({
        label: gst.percentage,
        value: gst.id,
      }));
      set_gst_option(gst_item);
    }
  }, [gst_list]);

  useEffect(() => {
    if (get_product_list_result) {
      const lock = get_product_list_result?.items?.some(
        (item: any) => item?.is_locked === true
      );
      set_lock_all_product(lock || false);
      set_lock_status(lock ? "locked" : "un_locked");
    }
  }, [get_product_list_result]);

  useEffect(() => {
    if (create_product_result?.status === "success") {
      toast.success("Product created successfully");
      close_modal(true);
      dispatch(create_product_clear());
      if (table_ref.current) {
        table_ref.current.load_data();
      }
    } else if (create_product_error) {
      toast.error(create_product_error?.message ?? "Failed to create product");
      dispatch(create_product_clear());
    } else if (update_product_result?.status === "success") {
      toast.success("Product updated successfully");
      close_modal(true);
      dispatch(update_product_clear());
      if (table_ref.current) {
        table_ref.current.load_data();
      }
    } else if (update_product_error) {
      toast.error(update_product_error?.message ?? "Failed to update product");
      dispatch(update_product_clear());
    }
  }, [
    update_product_result,
    create_product_result,
    create_product_error,
    update_product_error,
  ]);

  useEffect(() => {
    if (modal_type === "edit" && item_id) {
      // Load the data and set it in the form if editing
      dispatch(
        get_product_request({
          filter: {
            id: item_id,
          },
        })
      );
    } else {
      form.resetFields(); // Reset form fields for add mode
    }
  }, [modal_type, item_id]);

  useEffect(() => {
    if (product_result) {
      console.log(product_result, "product_result");

      form.setFieldsValue({
        image_list: product_result?.image_list?.[0]
          ? [
              {
                url: product_result?.image_list?.[0],
                status: "uploaded",
                uid: "rc-upload-1686120521566-5",
                name: "Image",
              },
            ]
          : [],
        name: product_result?.name,
        category_id: product_result?.category_id,
        hsn_code: product_result?.hsn_code,
        orderable_time_range: [
          dayjs(product_result?.orderable_from_time_in_mins),
          dayjs(product_result?.orderable_till_time_in_mins),
        ],
        orderable_dates: product_result?.orderable_dates,
        price: product_result?.price,
        description: product_result?.description,
        measurement_type: product_result?.measurement_type,
        gst_id: product_result?.gst_id,
        minimum_order_quantity: product_result?.minimum_order_quantity,
        minimum_order_amount: product_result?.minimum_order_amount,
      });
    }
  }, [product_result]);

  // const form_fields: DynamicFormField[] = [
  //   {
  //     name: "image_list",
  //     label: t(" "),
  //     type: "image",
  //     rules: [{ required: true, message: "Please upload image." }],
  //     className: "image-upload",
  //     layout: { span: 24, xs: 24 },
  //     labelCol: { xs: 24, sm: 6, lg: 7 },
  //     wrapperCol: { xs: 22, sm: 22 },
  //     maxCount: 1,
  //   },
  //   {
  //     name: "name",
  //     label: t("table:name"),
  //     type: "text",
  //     className: "name-box",
  //     rules: [
  //       { required: true, message: "Please enter Products name" },
  //       {
  //         pattern: /^[a-zA-Z0-9 ]+$/,
  //         message: "Only letters and numbers allowed.",
  //       },
  //     ],
  //     layout: { span: 24, xs: 24 },
  //     labelCol: { xs: 24, sm: 6, lg: 7 },
  //     wrapperCol: { xs: 24, sm: 18 },
  //   },
  //   {
  //     name: "category_id",
  //     label: t("table:category"),
  //     type: "select",
  //     rules: [{ required: true, message: "Please select category" }],
  //     source: {
  //       request: get_category_list_request,
  //       selector: get_category_list,
  //     },
  //     labelField: "name",
  //     valueField: "id",
  //     layout: { span: 24, xs: 24 },
  //     labelCol: { xs: 24, sm: 6, lg: 7 },
  //     wrapperCol: { xs: 24, sm: 18 },
  //   },
  //   {
  //     name: "hsn_code",
  //     label: t("table:hsn_code"),
  //     type: "text",
  //     className: "name-box",
  //     rules: [
  //       { required: true, message: "Please enter hsn code" },
  //       {
  //         pattern: /^\d{4}|\d{6}|\d{8}$/,
  //         message: "Please enter valid hsn code",
  //       },
  //     ],
  //     layout: { span: 24, xs: 24 },
  //     labelCol: { xs: 24, sm: 6, lg: 7 },
  //     wrapperCol: { xs: 24, sm: 18 },
  //     maxCount: 8,
  //   },

  //   {
  //     name: "orderable_from_time_in_mins",
  //     label: t("table:orderable_from_time_in_mins"),
  //     type: "TimePicker",
  //     className: "name-box",
  //     rules: [{ required: true, message: "Please select item available time" }],
  //     layout: { span: 24, xs: 24 },
  //     labelCol: { xs: 24, sm: 6, lg: 7 },
  //     wrapperCol: { xs: 24, sm: 18 },
  //   },
  //   {
  //     name: "orderable_till_time_in_mins",
  //     label: t("table:orderable_till_time_in_mins"),
  //     type: "TimePicker",
  //     className: "name-box",
  //     rules: [{ required: true, message: "Please select item closing time" }],
  //     layout: { span: 24, xs: 24 },
  //     labelCol: { xs: 24, sm: 6, lg: 7 },
  //     wrapperCol: { xs: 24, sm: 18 },
  //   },

  //   {
  //     name: "orderable_dates",
  //     label: t("table:orderable_dates"),
  //     type: "dayRanges",
  //     className: "name-box",
  //     rules: [
  //       { required: true, message: "Please select item available dates" },
  //     ],
  //     layout: { span: 24, xs: 24 },
  //     labelCol: { xs: 24, sm: 6, lg: 7 },
  //     wrapperCol: { xs: 24, sm: 18 },
  //   },
  //   {
  //     name: "price",
  //     label: t("table:price"),
  //     type: "number",
  //     rules: [{ required: true, message: "Please enter price" }],
  //     className: "name-box",
  //     layout: { span: 24, xs: 24 },
  //     labelCol: { xs: 24, sm: 6, lg: 7 },
  //     wrapperCol: { xs: 24, sm: 18 },
  //   },
  //   {
  //     name: "description",
  //     label: t("table:description"),
  //     type: "textarea",
  //     className: "name-box",
  //     layout: { span: 24, xs: 24 },
  //     labelCol: { xs: 24, sm: 6, lg: 7 },
  //     wrapperCol: { xs: 24, sm: 18 },
  //   },
  //   {
  //     name: "measurement_type",
  //     label: t("table:measurement_type"),
  //     type: "select",
  //     rules: [{ required: true, message: "Please select measurement type" }],
  //     options: measurement_type,
  //     className: "name-box",
  //     layout: { span: 24, xs: 24 },
  //     labelCol: { xs: 24, sm: 6, lg: 7 },
  //     wrapperCol: { xs: 24, sm: 18 },
  //   },
  //   {
  //     name: "gst_id",
  //     label: t("table:gst"),
  //     type: "select",
  //     className: "name-box",
  //     options: gst_option,
  //     rules: [{ required: true, message: "Please select gst" }],
  //     layout: { span: 24, xs: 24 },
  //     labelCol: { xs: 24, sm: 6, lg: 7 },
  //     wrapperCol: { xs: 24, sm: 18 },
  //   },
  // ];

  const columns: DynamicTableColumn[] = [
    {
      title: "Name",
      sorter: true,
      sorted_by: "name",
      render: (record: any) => (
        <div className="product-container">
          <img
            src={record?.image_list?.[0] || DEFAULT_IMAGE} // Fallback image URL
            className="product-image"
            alt={record?.name || "Product Image"}
          />
          <Link to={`/admin/products/product-log/${record?.id}`}>
            {record?.name || "Unnamed Product"}
          </Link>
        </div>
      ),
    },
    {
      title: "Category",
      data_index: "category.name",
      // sorter: true,
      sorted_by: "category.name",
    },
    {
      title: "HSN Code",
      data_index: "hsn_code",
      sorter: false,
    },
    {
      title: "Trending",
      sorter: false,
      render: (record: any) => (
        <Switch
          checked={record.is_trending}
          size="small"
          style={{
            boxShadow: "none",
            outline: "none",
            backgroundColor: record.is_trending ? "green" : "red",
          }}
          onChange={(value) => {
            dispatch(
              update_product_request({
                id: record?.id,

                data: { is_trending: value },
              })
            );
          }}
        />
      ),
    },
    {
      title: "Is Locked",
      sorter: false,
      render: (record: any) => (
        <Switch
          disabled
          checked={record.is_locked}
          size="small"
          style={{
            boxShadow: "none",
            outline: "none",
            backgroundColor: record.is_locked ? "green" : "red",
          }}
        />
      ),
    },
    {
      title: "Order From",
      data_index: "orderable_from_time_in_mins",
      // sorter: true,
      render: (record: Date) => moment(record).format("hh:mm A"),
    },
    {
      title: "Order Till",
      data_index: "orderable_till_time_in_mins",
      // sorter: true,
      render: (record: Date) => moment(record).format("hh:mm A"),
    },
    {
      title: "Actions",
      fixed: "right",
      action_access_key: "product_actions",
      actions: [
        {
          type: "edit",
          icon: <EditOutlined />,
          access_key: "update_product",
        },
        {
          type: "delete",
          icon: <DeleteOutlined />,
          access_key: "delete_product",
          title: "Are you sure you want to delete the Products {item_name}?",
          title_replace_field: "name",
          content: "Deleting this Products cannot be undone.",
          ok_text: "Yes",
          cancel_text: "No",
          source: {
            request: delete_product_request,
            selector: delete_product,
            filter_key: "id",
            filter_value_field: "id",
            response_path: "result.status",
            response_success_value: "success",
            response_success_message: "Products was successfully deleted",
            response_failure_message: "Products cannot be deleted",
          },
        },
      ],
    },
  ];

  const filters: DynamicFilterField[] = [
    {
      name: "category_id",
      label: "Category",
      type: "select",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
      source: {
        request: get_category_list_request,
        selector: get_category_list,
      },
    },
    {
      name: "name",
      label: " ",
      type: "search",
      class_name: "search-box",
      layout: { span: 6, xs: 8, xl: 6, xxl: 6 },
      label_col: { xs: 24, sm: 24 },
      wrapper_col: { xs: 24, sm: 24 },
    },
  ];

  const form_source: DynamicFormSource = {
    request: get_product_request,
    selector: get_product,
    params: {
      filter: { id: item_id },
    },
  };

  const form_actions: DynamicFormAction[] = [
    {
      type: "reset",
      label: "Reset",
    },
    {
      type: "cancel",
      label: "Cancel",
    },
    {
      type: "submit",
      label: "Save",
      create_source: {
        request: create_product_request,
        selector: create_product,
        params: {
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Products created successfully",
        response_failure_message: "Products cannot be created",
      },
      update_source: {
        request: update_product_request,
        selector: update_product,
        params: {
          id: item_id || "",
          data: {},
        },
        response_path: "result.status",
        response_success_value: "success",
        response_success_message: "Products updated successfully",
        response_failure_message: "Products cannot be updated",
      },
    },
  ];

  const on_action = (type: any, record?: any) => {
    if (type === "edit") {
      set_item_id(record.id);
      set_modal_type(type);
      set_is_modal_open(true);
    } else {
      if (type === "add") {
        form.resetFields();
        set_item_id(null);
        set_modal_type(type);
        set_is_modal_open(true);
      }
    }
  };

  const on_modal_action = (type: any, values: any) => {
    if (type === "submit") {
      if (modal_type === "add") {
        dispatch(create_product_request({ data: values }));
      } else {
        dispatch(update_product_request({ id: item_id || "", data: values }));
      }
      form.resetFields();
      close_modal(true);
    }
  };
  const close_modal = (reload: any) => {
    set_is_modal_open(false);
    set_item_id(null);
    set_modal_type("add");

    form.resetFields();

    if (reload && table_ref.current) {
      table_ref.current.load_data();
    }
  };
  const handle_reset = () => {
    form.resetFields();
  };

  const handle_product_lock_modal_close = () => {
    console.log("closing modal 3");
    dispatch(get_product_list_request({}));
    set_lock_modal(false);
    product_lock_form.resetFields();
    if (table_ref.current) {
      table_ref.current.load_data();
    }
  };

  const handle_submit = (values: any) => {
    values.orderable_from_time_in_mins = values.orderable_time_range?.[0];
    values.orderable_till_time_in_mins = values.orderable_time_range?.[1];
    delete values.orderable_time_range;

    console.log(values.orderable_dates);
    let usedRange: any = [];
    if (!values.orderable_dates.length) {
      return toast.error("Orderable Dates is required.");
    }
    for (let i = 0; i < values.orderable_dates.length; i++) {
      const { from, to } = values.orderable_dates[i];

      if (from > to) {
        return toast.error("Orderable Dates: from should be lesser than to");
      }
      if (from < 1 || to > 31) {
        return toast.error("Orderable Dates: Day ranges only between 1 to 31");
      }
      for (let j = 0; j < usedRange.length; j++) {
        const range = usedRange[j];
        if (
          (from >= range[0] && from <= range[1]) ||
          (to >= range[0] && to <= range[1])
        ) {
          return toast.error(
            "Orderable Dates: Day ranges should not be duplicate"
          );
        }
      }
      usedRange.push([from, to]);
    }

    if (modal_type === "add") {
      values.image_list = [values.image_list.file.url];
      dispatch(create_product_request({ data: values }));
    } else {
      values.image_list = [
        values.image_list?.[0]?.url || values.image_list.file.url,
      ];
      dispatch(update_product_request({ id: item_id || "", data: values }));
    }
  };
  return (
    <>
      <div className="product-page-title-container">
        <div className="product-page-title">
          <PageTitle is_color={true} title={t("products")} />
        </div>

        <div className="products-button">
          {useCheckPageComponentAccess("add_product_button") && (
            <>
              <Button type="primary" onClick={() => on_action("add")}>
                {t("add_product")}
              </Button>
              <Button
                type="primary"
                onClick={() => set_lock_modal(true)}
                className="lock-product-button"
              >
                {t("lock_product")}
              </Button>
            </>
          )}
        </div>
      </div>
      <br />
      <DynamicTable
        ref={table_ref}
        columns={columns}
        filters={filters}
        source={{
          request: get_product_list_request,
          selector: get_product_list,
          params: {
            filter: {},
          },
        }}
        pagination={{
          page_size: 10,
          show_total: "Total {total} Products",
        }}
        on_action={on_action}
      />
      {/* <DynamicForm
        view_type="modal"
        type={modal_type}
        modal_title={
          modal_type === "add" ? t("add_product") : t("edit_product")
        }
        is_modal_open={is_modal_open}
        close_modal={close_modal}
        source={form_source}
        fields={form_fields}
        actions={form_actions}
        on_action={on_modal_action}
      /> */}

      <Modal
        title={
          <ModalTitle
            title={modal_type === "add" ? t("add_product") : t("edit_product")}
          />
        }
        open={is_modal_open}
        onCancel={close_modal}
        closeIcon={<AiFillCloseCircle color="black" />}
        destroyOnClose={true}
        width={"50%"}
        centered
        footer={null}
      >
        <CreateProductForm
          form={form}
          handle_reset={handle_reset}
          handle_submit={handle_submit}
          close_modal={close_modal}
          create_product_loading={create_product_loading}
          update_product_loading={update_product_loading}
        />
      </Modal>
      <CommonModal
        title={
          lock_status === "locked" ? "Lock All Products" : "Unlock All Products"
        }
        open={lock_modal}
        onCancel={handle_product_lock_modal_close}
      >
        <UpdateProductLockModal
          product_lock_form={product_lock_form}
          lock_all_product={lock_all_product}
          onClose={handle_product_lock_modal_close}
        />
      </CommonModal>
    </>
  );
};

export default Products;
