import React from "react";

interface CategoryLabelProps {
  record: {
    image_list: string[];
    name: string;
  };
}

const CategoryLabel: React.FC<CategoryLabelProps> = ({ record }) => {
  return (
    <div className="category-container">
      <img
        src={record?.image_list[0]}
        className="product-image"
        alt={record?.name}
      />
      <h5 className="stock-category-name">{record?.name}</h5>
    </div>
  );
};

export default CategoryLabel;
