import {
  AppDispatch,
  create_customer_credit_request,
  create_order_line_item,
  create_order_line_item_clear,
  create_order_line_item_request,
  get_dispute,
  get_dispute_request,
  get_order,
  get_order_line_item_list,
  get_order_line_item_list_request,
  get_order_request,
  get_product_list,
  get_product_list_request,
  Product,
  update_dispute,
  update_dispute_clear,
  update_dispute_request,
  update_order,
  update_order_clear,
  update_order_line_item,
  update_order_line_item_clear,
  update_order_line_item_request,
  update_order_request,
} from "@src/redux";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

type Props = {
  payment_status_form: any;
  line_item?: any;
  onClose: Function;
  dispute_status?: string;
};

interface StatusOption {
  label: string;
  value: string;
}

const UpdateOrderPaymentModal = (props: Props) => {
  const { payment_status_form, onClose, line_item } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams<{ id: string }>();

  const {
    result: update_order_result,
    loading: update_order_loading,
    error: update_order_error,
  } = useSelector(update_order);
  console.log(update_order_loading, update_order_result, "update_order_result");

  useEffect(() => {
    if (update_order_result?.status === "success") {
      dispatch(get_order_request({ filter: { id: id } }));
      onClose();
      payment_status_form.resetFields();
      dispatch(update_order_clear());
    } else if (update_order_error) {
      payment_status_form.resetFields();
      toast.error(update_order_error.message || "");
      dispatch(update_order_clear());
    }
  }, [update_order_result, update_order_error]);

  const on_finish = (values: any) => {
    if (id) {
      dispatch(
        update_order_request({
          id,
          data: {
            mode_of_payment: values?.mode_of_payment,
            payment_transaction_reference:
              values?.payment_transaction_reference,
            payment_status: "paid",
            payment_received_datetime: values?.payment_received_datetime,
          },
        })
      );
    }
  };

  return (
    <div>
      <Form form={payment_status_form} onFinish={on_finish} layout="vertical">
        <Form.Item
          name={"mode_of_payment"}
          label={t("Mode of payment")}
          rules={[
            {
              required: true,
              message: "Please enter payment mode.",
            },
          ]}
        >
          <Input style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name={"payment_transaction_reference"}
          label={t("Transaction reference number")}
          rules={[
            {
              required: true,
              message: "Please enter order payment transaction reference.",
            },
          ]}
        >
          <TextArea style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name={"payment_received_datetime"}
          label={t("Payment received date")}
          rules={[
            {
              required: true,
              message: "Please select the payment received date.",
            },
          ]}
        >
          <DatePicker
            style={{ width: "100%" }}
            disabledDate={(current) => {
              return current && current >= moment().endOf("day");
            }}
          />
        </Form.Item>

        <Row justify={"end"} gutter={10}>
          <Col>
            <Button danger onClick={() => onClose()}>
              {t("close")}
            </Button>
          </Col>
          <Col>
            <Button
              htmlType="submit"
              type="primary"
              loading={update_order_loading}
            >
              {t("submit")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UpdateOrderPaymentModal;
