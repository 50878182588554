import { combineReducers } from "@reduxjs/toolkit";
import { dynamic_reducer } from "./slices/dynamic";
import brand_fee_reducer from "./slices/brand_fee/reducer";
import category_reducer from "./slices/category/reducer";
import customer_reducer from "./slices/customer/reducer";
import customer_credit_reducer from "./slices/customer_credit/reducer";
import dispute_reducer from "./slices/dispute/reducer";
import dispute_line_item_reducer from "./slices/dispute_line_item/reducer";
import district_reducer from "./slices/district/reducer";
import forgot_password_log_reducer from "./slices/forgot_password_log/reducer";
import franchise_reducer from "./slices/franchise/reducer";
import gst_reducer from "./slices/gst/reducer";
import order_reducer from "./slices/order/reducer";
import order_line_item_reducer from "./slices/order_line_item/reducer";
import order_payment_transaction_reducer from "./slices/order_payment_transaction/reducer";
import product_reducer from "./slices/product/reducer";
import product_stock_reducer from "./slices/product_stock/reducer";
import product_stock_log_reducer from "./slices/product_stock_log/reducer";
import state_reducer from "./slices/state/reducer";
import system_api_module_reducer from "./slices/system_api_module/reducer";
import system_file_reducer from "./slices/system_file/reducer";
import system_otp_reducer from "./slices/system_otp/reducer";
import system_role_reducer from "./slices/system_role/reducer";
import system_ui_module_reducer from "./slices/system_ui_module/reducer";
import system_ui_module_component_reducer from "./slices/system_ui_module_component/reducer";
import system_user_reducer from "./slices/system_user/reducer";

const reducer = combineReducers({
  dynamic: dynamic_reducer,
  brand_fee: brand_fee_reducer,
  category: category_reducer,
  customer: customer_reducer,
  customer_credit: customer_credit_reducer,
  dispute: dispute_reducer,
  dispute_line_item: dispute_line_item_reducer,
  district: district_reducer,
  forgot_password_log: forgot_password_log_reducer,
  franchise: franchise_reducer,
  gst: gst_reducer,
  order: order_reducer,
  order_line_item: order_line_item_reducer,
  order_payment_transaction: order_payment_transaction_reducer,
  product: product_reducer,
  product_stock: product_stock_reducer,
  product_stock_log: product_stock_log_reducer,
  state: state_reducer,
  system_api_module: system_api_module_reducer,
  system_file: system_file_reducer,
  system_otp: system_otp_reducer,
  system_role: system_role_reducer,
  system_ui_module: system_ui_module_reducer,
  system_ui_module_component: system_ui_module_component_reducer,
  system_user: system_user_reducer,
});

export default reducer;
